import React from "react";
import { useDispatch } from 'react-redux'

import * as constants from '../constants';

import { BXDownloadCsv } from './BXDownloadCsv';
import { BXDownloadExcel } from './BXDownloadExcel';
import { HXFilterBox } from './HXFilterBox';
import { BXWordsListControls } from './BXWordsListControls';

export const BXControls = (props) => {

  const dispatch = useDispatch();
  const handleOpenHelpDialog = () => {
    dispatch({type: 'help/dialogIsOpen', payload: true});
  }

  return (
    <>
      <div
        name="filters"
        className="-mp-lay-fl"
        style={{clear:'both',width:'100%'}}>
        <div name="label_012" className="controls-label">
          FILTERS
        </div>
        <div
          style={{display:'flex',justifyContent:'space-between',width:'975px'}}>

          <HXFilterBox
            filterName="word"
            title="Word"
            availableConditions={constants.TEXTUAL_CONDITIONS}
          />
          <HXFilterBox
            filterName="freq_pm"
            title="Freq / M (Frequency per Million)"
            availableConditions={constants.NUMERICAL_CONDITIONS}
          />
          <HXFilterBox
            filterName="letter_length"
            title="Letter Length"
            availableConditions={constants.NUMERICAL_CONDITIONS}
          />

          <HXFilterBox
            filterName="OLD20orth"
            title="old20orth"
            availableConditions={constants.NUMERICAL_CONDITIONS}
          />
          <HXFilterBox
            filterName="OLD20phon"
            title="old20phon"
            availableConditions={constants.NUMERICAL_CONDITIONS}
          />
        </div>

        <div
          name="help_btn"
          className="-mp-lay-fl"
          onClick={handleOpenHelpDialog}
          style={{borderColor:'#888888',borderRadius:'50%',borderStyle:'solid',borderWidth:'0px',cursor:'pointer',float:'right',height:'30px',position:'relative',width:'30px',}} >
          <div name="label"
            style={{color:'#666666',fontSize:'26px',fontWeight:'bold',lineHeight:'30px',textAlign:'center',width:'100%',}}>
            ?
          </div>
        </div>
      </div>
      <div
        name="select"
        className="-mp-lay-fl"
        style={{margin:'20px 0px 20px 0px', width:'100%'}} >
        <div name="label" className="controls-label">
          SELECT
        </div>
        <div
          className="-mp-lay-fl"
          style={{width:'975px'}}>
          <BXWordsListControls />

          <BXDownloadCsv />

          <BXDownloadExcel />
        </div>
        <div name="padder" className="-mp-lay-fl"
          style={{float:'right',height:'30px',position:'relative',width:'30px',}}>
        </div>
      </div>
    </>
  )

}

BXControls.propTypes = {
}
