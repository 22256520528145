import React, {useState, useRef, useEffect, useMemo} from "react";
import PropTypes from 'prop-types';

import { Bounds } from '../../util/Plane';
import * as Browser from '../../util/Browser';

const DEFAULT_WIDTH = 332;

export const HXWindow = (props) => {
  const [adjustedStyle, setAdjustedStyle] = useState({});
  const [localStyle, setLocalStyle] = useState({});

  const thisElem = useRef(null);

  useMemo( () => {
    setLocalStyle({
      // visibility: 'hidden',
      margin: '0px',
      position: 'fixed',
      width: '332px',
      visibility: 'visible',
      zIndex: '10',
      left: '461.5px',
      top: '304.384px',
      opacity: '1',
      ...adjustedStyle,
    })
  }, [adjustedStyle] );


  useEffect( () => {
    const width = props.width || DEFAULT_WIDTH;
    const height = thisElem.current.clientHeight;
    const winBounds = Browser.wind_port.getWinBounds();
    winBounds.setBot(winBounds.getBot() * 0.66);
    let thisBounds = new Bounds(width, height);
    thisBounds = thisBounds.centerIn(winBounds);
    const newStyle = {
      ...localStyle,
      visibility: 'visible',
      width: width + 'px',
      left: thisBounds.getLeft() + 'px',
      top: thisBounds.getTop() + 'px',
    };
    const styleChanges = Object.keys(newStyle).filter( k => {
      return adjustedStyle[k] !== newStyle[k];
    });
    if(styleChanges.length) {
      setAdjustedStyle(newStyle);
    }
  }, [adjustedStyle, localStyle, props.width]);


  return (
    <>
      {/* <div name="dialog-blocker" className="-mp-window-blocker"></div> */}
      <div ref={thisElem} style={localStyle} className="bilex-window -mp-lay-fl" >
        <table name="whead" className="bilex-window-whead" cellPadding="0" cellSpacing="0">
            <tbody name="!klgw">
              <tr name="wrow" className="bilex-window-wrow">
                <td name="wlcell" className="bilex-window-wrcell bilex-window-wrcell--alert">
                  <div name="title_bar" className="bilex-window-title-bar bilex-window-title-bar--alert"></div>
                </td>
              </tr>
            </tbody>
        </table>
        <div className="bilex-window-body bilex-window-body--alert">
            <div name="head" className="text_base bilex-window-head -mp-lay-fl">
              <div name="message" className="bilex-window-head-message">
                  {props.title}
              </div>
            </div>
            <div name="contents" className="bilex-window-contents -mp-lay-fl">
              {props.children}
            </div>
        </div>
        <div name="wfold_fg" className="-mp-wfold-fg"></div>
      </div>
    </>
  )
}

HXWindow.propTypes = {
  title: PropTypes.string,
  width: PropTypes.number,
}