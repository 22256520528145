import React from "react";
import PropTypes from 'prop-types';

export const HXTableEmptyBody = (props) => {

  return (
    <tr>
      <td
        colSpan={props.columnNames.length + 1}>
          No Words
      </td>
    </tr>
  )
}


HXTableEmptyBody.propTypes = {
  columnNames: PropTypes.arrayOf(PropTypes.string),
}
