import infoImage from '../images/plus-help-graphic.svg'

import { BXPlusHeader } from '../ui/BXPlusHeader';

export function BXPlusInfoPage() {

  return (
    <div name="page_contents"
      className="page-contents dark"
    >
      <div
        className="vert-flex-start"
      >
        <div
          name="slot"
          style={{flexGrow:1,minHeight:'160px',minWidth:'100px',position:'relative',margin:'0px'}}
        >
          <BXPlusHeader/>

          <div
            style={{alignItems:'flex-start',backgroundColor:'#ffffff',borderRadius:'55px',display:'flex',flexDirection:'column',justifyContent:'space-around',overflowX:'hidden',overflowY:'hidden',position:'relative',width:'100%',}}
          >
            <h1
              className="plus-heading">
              Info
            </h1>
            <div
              name="about_text"
              style={{alignItems:'flex-start',display:'flex',flexDirection:'row',fontSize:'14px',justifyContent:'space-between',margin:'0px 20px 0px 20px',overflowX:'hidden',overflowY:'hidden',position:'relative',}}
            >
              <div
                className="-mp-lay-fl"
                style={{width:'26%',}}
              >
                <p
                  style={{margin:'0px',}}
                >
                  There are 10 columns in the total lexicon that correspond to 10 variables: Frequency Counts (F), Zipf, Frequency per million(F/m), Log Frequency per million(LogF/m), Zipf, Dispersion (D), Estimated frequency per million (U), Standard Frequency Index (SFI), Contextual Diversity (CD), orthographic and phonological Levenshtein distance (OLD20), orthographic and phonological neighbourhood (based on Coltheart’s N), number of Letters, syllables, number of syllables, stress position, phonemic transcription, number of phonemes, unstressed orthographic and phonological form, pronunciation (sound files), translation in English.
                </p>
                <p>
                  The users can apply search criteria and choose filter variables, paste lists of words and extract results to csv files or do word by word search. They can also download the lexicons as excel files.
                </p>
              </div>
              <div
                className="help-div -mp-lay-fl"
                style={{width:'26%',}}
              >
                <p
                  className="lang-en">
                  <span
                    className="help-head-blue">
                    Frequency Counts (F)
                  </span>: raw number of word occurrences in the textbooks.<br/>
                </p>
                <p
                  className="lang-en">
                  <span
                    className="help-head-orange">
                    Zipf
                  </span>: This is a standardised frequency value expressed in a logarithmic scale first introduced by vanHeuven et al (2014). The scale ranges from 1 (very low frequency) to 7 (very high frequency).<br/>
                </p>
                <p
                  className="lang-en">
                  <span className="help-head-blue">
                    Frequency per million (Freq_pm)
                  </span>: is calculated as Frequency Counts * 1,000,000/Number of tokens.<br/>
                </p>
                <p
                  className="lang-en">
                  <span className="help-head-orange">
                    Orthographic Levenshtein Distance (OLD20 Orth)
                  </span>: This is the average number of substitutions, deletions and additions between a word and its 20 closest orthographic neighbours that are necessary to turn one word into another.<br/>
                </p>
                <p
                  className="lang-en">
                  <span className="help-head-blue">
                    Phonological Levenshtein Distance (OLD20 Phon)
                  </span>: This is the average number of substitutions, deletions and additions between a word and its 20 closest phonological neighbours that are necessary to turn one word into another.<br/>
                </p>
                <p
                  className="lang-en">
                  <span className="help-head-orange">
                    Orthographic Neighborhood (ColtNorth)
                  </span>: This is the number of words produced when replacing any letter in a word with another letter on the same position (Coltheart et al., 1977).<br/>
                </p>
              </div>
              <div
                className="help-div -mp-lay-fl"
                style={{width:'26%',}}
              >
                <p
                  className="lang-en">
                  <span className="help-head-blue">
                    Phonological Neighborhood (ColtPhon)
                  </span>: This is the number of words produced when replacing any phoneme in a word with another phoneme on the same position (Coltheart et al., 1977).<br/>
                </p>
                <p
                  className="lang-en">
                  <span className="help-head-orange">
                    Number of letters (letter_length)
                  </span>: The number of letters within a word (word length)<br/>
                </p>
                <p
                  className="lang-en">
                  <span className="help-head-blue">
                    Syllables
                  </span>: Syllabified wordsNumber of syllables (syl_length): Number of syllables per syllabified word<br/>
                </p>
                <p
                  className="lang-en">
                  <span className="help-head-orange">
                    Stress position (stress_pos)
                  </span>: numbered position of stress (1 = stress on ultimate position, 2 = stress on penultimate position, 3 = stress on ante-penultimate position)<br/>
                </p>
                <p
                  className="lang-en">
                  <span className="help-head-blue">
                    Phonemes
                  </span>: IPA Phonemic transcription of wordsNumber of Phonemes (phon_length): Number of phonemes per word<br/>
                </p>
                <p
                  className="lang-en">
                  <span className="help-head-orange">
                    Unstressed orthographic and phonological form (unstressed_orth, unstressed_phon)
                  </span>: Orthographic and phonological form of the word without stress<br/>
                </p>
                <p
                  className="lang-en">
                  <span className="help-head-blue">
                    Sounds
                  </span>: words pronounced by Greek native speakers.<br/>
                </p>
                <p
                  className="lang-en">
                  <span className="help-head-orange">
                    Translations
                  </span>: The first three translations as they appear in Oxford Greek-English Learner's Dictionary (Stavropoulos, 2008)</p>
              </div>
              <div
                name="graphic"
                style={{height:'379px',position:'relative',width:'174px',}}
              >
                <img
                  style={{bottom:'38.7376px',height:'183.262px',position:'absolute',right:'0px',width:'174px',}}
                  src={infoImage}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}