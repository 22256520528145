import { useSelector } from 'react-redux'

import { columnInfos, columnNames } from '../data/ColumnInfos';

import { BXControls } from '../ui/BXControls';
import { BXPlusHeader } from '../ui/BXPlusHeader';
import { HXHelpDialog } from '../ui/HXDialogs/HXHelpDialog';
import { HXTable } from '../ui/HXTable/HXTable';
import { HXWordListDialog } from '../ui/HXDialogs/HXWordListDialog';

export function BXDataPage() {
  const selectedLanguage = useSelector(state => state.selectedLanguage);
  const helpDialogIsOpen = useSelector(state => state.help.dialogIsOpen);
  const wordsListDialogIsOpen = useSelector(state => state.wordsList.dialogIsOpen);

  let className = 'page-contents dark -mp-lay-fl ';
  className += (selectedLanguage === 'EL') ? 'EL-ACTIVE' : 'EN-ACTIVE';

  return (
    <div
      name="page_contents"
      className={className}
    >
    <div
      name="slot"
      style={{margin:'0px 6px 20px 6px',minheight:'160px',minWidth:'100px',}}
    >

      <BXPlusHeader/>

      <HXTable
        columnInfos={columnInfos}
        columnNames={columnNames}
      />
    </div>
      <BXControls />

      {
        helpDialogIsOpen &&
        <HXHelpDialog />
      }
      {
        wordsListDialogIsOpen &&
        <HXWordListDialog />
      }

    </div>
  );
}
