import face01 from '../images/faces/face-01.svg'
import face02 from '../images/faces/face-02.svg'
import face03 from '../images/faces/face-03.svg'
import face04 from '../images/faces/face-04.svg'
import face05 from '../images/faces/face-05.svg'
import face06 from '../images/faces/face-06.svg'
import face07 from '../images/faces/face-07.svg'
import face08 from '../images/faces/face-08.svg'
import face09 from '../images/faces/face-09.svg'
import face10 from '../images/faces/face-10.svg'


export function BXKidsFooter() {
  return (
    <div name="head-pics"
      style={{alignItems:'flex-end',display:'flex',flexDirection:'row',flexGrow:0,flexShrink:0,justifyContent:'space-evenly',margin:'15px 0px -5px 0px',width:'100%'}}>
      <img
        style={{height:'122.047px',width:'75px'}}
        src={face01}
        alt=""
      />
      <img
        style={{height:'117.54px',width:'83px'}}
        src={face02}
        alt=""
      />
      <img
        style={{height:'131.573px',width:'98px'}}
        src={face03}
        alt=""
      />
      <img
        style={{height:'118.156px',width:'85px'}}
        src={face04}
        alt=""
      />
      <img
        style={{height:'123.344px',width:'94px'}}
        src={face05}
        alt=""
      />
      <img
        style={{height:'123.103px',width:'94px'}}
        src={face06}
        alt=""
      />
      <img
        style={{height:'127px',width:'84px'}}
        src={face07}
        alt=""
      />
      <img
        style={{height:'114.102px',width:'80px'}}
        src={face08}
        alt=""
      />
      <img
        style={{height:'111.295px',width:'80px'}}
        src={face09}
        alt=""
      />
      <img
        style={{height:'111.487px',width:'80px'}}
        src={face10}
        alt=""
      />
        </div>
  )
}