export const columnInfos = {
  word: { name: "word", title: "Word", },
  word_all_lower: { name: "word_all_lower", title: "", },
  counts: { name: "counts", title: "Counts", },
  freq_pm: { name: "freq_pm", title: "Freq / M", },
  zipf: { name: "zipf", title: "Zipf", },
  letter_length: { name: "letter_length", title: "Letter Length", },
  syllables: { name: "syllables", title: "Syllables", },
  syl_length: { name: "syl_length", title: "Syllables", },
  stress_pos: { name: "stress_pos", title: "Stress Pos", },
  phones: { name: "phones", title: "Phones", },
  phon_length: { name: "phon_length", title: "", },
  unstressed_orth: { name: "unstressed_orth", title: "", },
  unstressed_phon: { name: "unstressed_phon", title: "Unstressed Phon", },
  ColtNorth: { name: "ColtNorth", title: "", },
  ColtNphon: { name: "ColtNphon", title: "", },
  OLD20orth: { name: "OLD20orth", title: "Old 20 Orth", },
  OLD20phon: { name: "OLD20phon", title: "Old 20 Phon", },
  SOUNDS: { name: "SOUNDS", title: "", },
  TRANSLATION: { name: "TRANSLATION", title: "", },
  // TRANSLATION: { name: "", title: "", },
  // TRANSLATION: { name: "", title: "", },
}

// this specifies the order of the columns
export const columnNames = [
  "word",
  "counts",
  "freq_pm",
  "zipf",
  "letter_length",
  "syl_length",
  "stress_pos",
  "phones",
  "unstressed_phon",
  "OLD20orth",
  "OLD20phon",
]