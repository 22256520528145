import { useSelector } from 'react-redux'

import { BXTranslationLevel } from '../ui/BXTranslationLevel';
import { BXTranslationList } from '../ui/BXTranslationList';
import { BXKidsFooter } from '../ui/BXKidsFooter';
import { BXKidsHeader } from '../ui/BXKidsHeader';


export function BXListingPage() {
  const selectedLanguage = useSelector(state => state.selectedLanguage);

  let className = 'page-contents -mp-lay-fl vert-space-between ';
  className += (selectedLanguage === 'EL') ? 'EL-ACTIVE' : 'EN-ACTIVE';

  return (
    <div
      name="page_contents"
      className={className}
    >
      <div
        name="slot"
        style={{flexGrow:1,minHeight:'160px',minWidth:'100px',width:'100%',}}
      >
        <BXKidsHeader/>

        <BXTranslationLevel />

        <div
          className="list-container"
          style={{height:'800px'}}
        >
          <BXTranslationList />
        </div>
      </div>

      <BXKidsFooter />
    </div>
  );
}
