import React, {useState} from "react";
import PropTypes from 'prop-types';

export const HXCheckBox = (props) => {
  const [isChecked, setIsChecked] = useState(props.isChecked);

  const handleCheckboxChange = event => {
    setIsChecked(!isChecked)
    props.onChange(!isChecked);
  }

  let checkStyle = 'check -hx-check-box';
  if (isChecked) {
    checkStyle += ' CHECKED';
  }
  return (
    <div name="active" className="active-check -mp-lay-fl">
      <div name="check_box" className={checkStyle}>
        <div name="inner"></div>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
      </div>
      <div name="label" className="col-name-lbl">
        {props.label}
      </div>
    </div>
  );
};

HXCheckBox.propTypes = {
  label: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
}