import * as Exception from './Exception';
import * as LOG from './LOG';
import * as Util from './Util';

const isDef = Util.isDef;
const mround = Math.round;
const mmax = Math.max, mmin = Math.min;

export class Loc {

  constructor( /*args*/ ) {
      /**
      args:
          iloc (Plane.Loc): another Loc object
      OR
      args:
          ihorz (number): a horizontal component
          ivert (number): a vertical component
      */
      let [ horz, vert ] = hvFromArgs.apply( null, arguments );
      this._ = { horz: horz, vert: vert };
  }

  toString() {
      /**
      return:
          string: a string representation of us
      */
      return Util.reprHelper( this, { horz: this._.horz, vert: this._.vert } );
  }


  copy() {
      /** return:
              Plane.Loc: a copy of us
      */
      return new Loc( this );
  }

  round() {
      /* return new Loc with all values rounded */
      return new Loc( [ this._.horz, this._.vert ].map( v => Math.round( v ) ) );
  }

  equals( other ) {
      /**
      do we equal another Loc?
      args:
          other (Plane.Loc): the other location to compare to
      return:
          boolean: true if locations have same horizontal and vertical components
      */
      return this._.horz === other._.horz && this._.vert === other._.vert;
  }


  isOrigin() {
      /**
      do we represent the origin (0,0)?

      return:
          boolean: true if both horizontal and vertical components are 0
      */
      return this._.horz === 0 && this._.vert === 0;
  }

  asInts() {
      /**
      return:
          Plane.Loc: with horizontal and vertical components rounded to the
              nearest integer
      example:
          new Loc( 10.3, 20.7 ).asInts() => Loc( 10, 21 )
      */
      return new Loc( mround( this._.horz ), mround( this._.vert ) );
  }
  negate() {
      /**
      return:
          Plane.Loc: with horizontal and vertical components negated
      example:
          new Loc( 10, 20 ).negate() => Loc( -10, -20 )
      */
      return new Loc( -this._.horz, -this._.vert );
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  getHorz() {
      /**
      return:
          number: the horizontal component
      */
      return this._.horz;
  }
  setHorz( horz ) {
      /**
      args:
          horz (number): the horizontal component
      */
      this._.horz = horz;
  }
  getVert() {
      /**
      return:
          number: the horizontal component
      */
      return this._.vert;
  }
  setVert( vert ) {
      /**
      args:
          vert (number): the horizontal component
      */
      this._.vert = vert;
  }
  set( vals ) {
      for( let [ n, v ] of Object.entries( vals ) ) {
          LOG.ASSERT( [ "horz", "vert" ].includes( n  ), `invalid name - ${ n }!` );
          this._[ n ] = v;
      }
  }

  zzz_set( vals ) {
      /* return modified version */
      let b = new Loc( this );
      for( let [ n, v ] of Object.entries( vals ) ) {
          LOG.ASSERT( [ "horz", "vert" ].includes( n  ), `invalid name - ${ n }!` );
          b._[ n ] = v;
      }
      return b;
  }


  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // return new Loc, offset by given amount - either horz, vert values,
  //        or another Loc
  offset( /*args*/ ) {
      /**
      move the location
      args:
          ihorz (number): the horizontal distance to move
          ivert (number): the vertical distance to move
      OR
      args:
          iloc (Plane.Loc): contains the horizontal and vertical distances
              to move
      return:
          Plane.Loc: the offset location
      example:
          new Loc( 10, 20 ).offset( 3, 4 ) => Loc( 13, 24 )
          new Loc( 10, 20 ).offset( new Loc( 3, 4 ) ) => Loc( 13, 24 )
      */
      let [ horz, vert ] = hvFromArgs.apply( null, arguments );
      return new Loc( this._.horz + horz, this._.vert + vert );
  }
  diffLoc( other ) {
      /**
      get the difference between the horizontal and vertical components of
      2 locations
      args:
          other (Plane.Loc): the other location
      return:
          Plane.Loc: the differenced location
      example:
          new Loc( 10, 20 ).diffLoc( new Loc( 3, 4 ) ) => Loc( -7, -16 )
      note:
          this is the same idea as subLoc(), but with opposite sign
              */
      return new Loc( other.getHorz() - this._.horz, other.getVert() - this._.vert );
  }


  // same as offset() but only takes Loc argument
  addLoc( other ) {
      /**
      add the horizontal and vertical components of 2 locations
      args:
          other (Plane.Loc): the other location
      return:
          Plane.Loc: the summed location
      example:
          new Loc( 10, 20 ).addLoc( new Loc( 3, 4 ) ) => Loc( 13, 24 )
      note:
          this has the same effect as offset() but only takes Loc argument
      */
      return new Loc( other.getHorz() + this._.horz, other.getVert() + this._.vert );
  }

  // this _is_ the right way round
  subLoc( other ) {
      /**
      subtract the horizontal and vertical components of 2 locations
      args:
          other (Plane.Loc): the other location
      return:
          Plane.Loc: the subtracted location
      example:
          new Loc( 10, 20 ).subLoc( new Loc( 3, 4 ) ) => Loc( 7, 16 )
      note:
          this is the same idea as diffLoc(), but with opposite sign
      */
      return new Loc( other.getHorz() - this._.horz, other.getVert() - this._.vert );
  }

  // the same idea as subLoc(), but with opposite sign
  // diffLoc( other ) {
  //     return new Loc( this._.horz - other.getHorz(), this._.vert - other.getVert() );
  // }

  mulLoc( other ) {
      /**
      multiply the horizontal and vertical components of 2 locations
      args:
          other (Plane.Loc): the other location
      return:
          Plane.Loc: the multiplied location
      example:
          new Loc( 10, 20 ).mulLoc( new Loc( 3, 4 ) ) => Loc( 30, 80 )
              */
      return new Loc( this._.horz * other.getHorz(), this._.vert * other.getVert() );
  }
  divLoc( other ) {
      /**
      divide the horizontal and vertical components of 2 locations
      args:
          other (Plane.Loc): the other location
      return:
          Plane.Loc: the divided location
      example:
          new Loc( 10, 20 ).divLoc( new Loc( 3, 4 ) ) => Loc( 3.3333, 5 )
              */
      return new Loc( this._.horz / other.getHorz(), this._.vert / other.getVert() );
  }
  scale( scale_horz, scale_vert ) {
      /**
      multiply the horizontal and vertical components

      args:
          iscale (number): the number by which to multiply both horizontal and
      vertical components

      OR

      args:
          scale_horz (number): the number by which to multiply the horizontal
      component
          scale_vert (number): the number by which to multiply the vertical
      component

      return:
          Plane.Loc: the scaled location

      example:
          new Loc( 10, 20 ).scale( 1.5 ) => Loc( 15, 30 )
          new Loc( 10, 20 ).scale( 1.5, 2 ) => Loc( 15, 40 )
              */
      if( !isDef( scale_vert ) ) {
          scale_vert = scale_horz;
      }
      return new Loc( this._.horz * scale_horz, this._.vert * scale_vert );
  }
  distanceToLoc( other ) {
      /**
      return the distance between us and another loc

      args:
          other (Plane.Loc): : the other location

      return:
          number: the distance
              */
      var h = this._.horz - other.getHorz();
      var v = this._.vert - other.getVert();
      return Math.sqrt( h * h + v * v );
  }
  toJSON() {
      return this._;
  }
}


export class Bounds {

  constructor( /*args*/ ) {
      let [ t, r, b, l ] = trblFromArgs.apply( null,  arguments );
      this._ = { top: t, right: r, bot: b, left: l };
  }

  toString() {
      /**
      return:
          string: a string representation of us
      */
      return Util.reprHelper( this, { t: this._.top, r: this._.right, b: this._.bot, l: this._.left,
                                                                          _order: "t r b l" } );
  }

  copy() {
      return new Bounds( this );
  }

  round() {
      /* return new Bounds with all values rounded */
      return new Bounds( [ this._.top, this._.right, this._.bot, this._.left ]
                                                              .map( v => Math.round( v ) ) );
  }

  equals( other ) {
      /**
      do we equal another Bounds?
      args:
          other (Plane.Bounds): the other bounds to compare to
      return:
          boolean: true if bounds have same top, right, bot, left components
  */
      return this._.top === other._.top && this._.right === other._.right &&
                                  this._.bot === other._.bot && this._.left === other._.left;
  }

  sizeEquals( other ) {
      return this.getWidth() === other.getWidth() && this.getHeight() === other.getHeight();
  }

  asArray() {
      /**
      return:
          array: all components in trbl order
      */
      return [ this._.top, this._.right, this._.bot, this._.left ];
  }
  asObject() {
      /**
      return:
          object: with trbl component attributes
      */
      return { t: this._.top, r: this._.right, b: this._.bot, l: this._.left };
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  getTop() {
      /**
      return:
          number: the top component
      */
      return this._.top;
  }
  setTop( top ) {
      /**
      args:
          top (number): the top component
              */
      this._.top = top;
  }
  getRight() {
      /**
      return:
          number: the right component
      */
      return this._.right;
  }
  setRight( right ) {
      /**
      args:
          right (number): the right component
              */
      this._.right = right;
  }
  getBot() {
      /**
      return:
          number: the bottom component
      */
      return this._.bot;
  }
  setBot( bot ) {
      /**
      args:
          bot (number): the bottom component
              */
      this._.bot = bot;
  }
  getLeft() {
      /**
      return:
          number: the left component
      */
      return this._.left;
  }
  setLeft( left ) {
      /**
      args:
          left (number): the left component
              */
      this._.left = left;
  }

  set( vals ) {
      for( let [ n, v ] of Object.entries( vals ) ) {
          LOG.ASSERT( [ "top", "right", "bot", "left" ].includes( n ), `invalid name - ${ n }` );
          this._[ n ] = v;
      }
  }

  getLeftTop() {
      /**
      return:
          Plane.Loc: the left-top of the bounds
      */
      return new Loc( this._.left, this._.top );
  }
  getRightTop() {
      /**
      return:
          Plane.Loc: the right-top of the bounds
      */
      return new Loc( this._.right, this._.top );
  }
  getLeftBot() {
      /**
      return:
          Plane.Loc: the left-bottom of the bounds
      */
      return new Loc( this._.left, this._.bot );
  }
  getRightBot()
      /**
      return:
          Plane.Loc: the right-bottom of the bounds
      */
      { return new Loc( this._.right, this._.bot ); }

  getCenter() {
      /**
      return:
          Plane.Loc: the center of the bounds
      */
      return new Loc( this._.left + this.getWidth() / 2, this._.top + this.getHeight() / 2 );
  }

  getTrbl() {
      return [ this._.top, this._.right, this._.bot, this._.left ];
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  getWidth() {
      /**
      return:
          number: the width of the bounds
      */
      return this._.right - this._.left;
  }
  setWidth( width ) {
      /**
      args:
          width (number): the width
              */
      this._.right = this._.left + width;
  }
  getHeight() {
      /**
      return:
          number: the height of the bounds
      */
      return this._.bot - this._.top;
  }
  setHeight( height ) {
      /**
      args:
          height (number): the height
              */
      this._.bot = this._.top + height;
  }
  getArea() {
      /**
      return:
          number: the area of the bounds
      */
      return this.getWidth() * this.getHeight();
  }
  getAspect() {
      /**
      return:
          number: the aspect ratio of the bounds
      */
      return this.getWidth() / this.getHeight();
  }

  isEmpty() {
      /**
      an empty bounds may have a width or a height but not both
      return:
          bool: true if we have 0 width *and / or* 0 height
      */
      return this._.right <= this._.left || this._.bot <= this._.top;
  }
  isVoid() {
      /**
      a void bounds has neither width nor height
      return:
          bool: true if we have 0 width *and* height
      */
      return this._.right <= this._.left && this._.bot <= this._.top;
  }

  // def isLarger( this, iother ):
  //     return this.getWidth() > iother.getWidth() && this.getHeight() > iother.getHeight()
  // def isSmaller( this, iother ):
  //     return this.getWidth() <= iother.getWidth() && this.getHeight() <= iother.getHeight()
  fitsInside( other ) {
      /**
      args:
          other (Plane.Bounds): a bounds to compare to
      return:
          bool: true our width and height are the same or smaller than other's
      */
      return this.getWidth() <= other.getWidth() && this.getHeight() <= other.getHeight();
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  normalize() {
      /**
      set left and top to set to 0 whilst preserving our width and height
      return:
          Plane.Bounds: a new normalized bounds
      */
      return new Bounds( this._.right - this._.left, this._.bot - this._.top );
  }
  offset( /*args*/ ) {
      /**
      move the bounds
      args:
          ihorz (number): the horizontal distance to move
          ivert (number): the vertical distance to move
      OR
      args:
          iloc (Plane.Loc): contains the horizontal and vertical distances
              to move
      return:
          Plane.Bounds: a new offset bounds
      */
      let [ horz, vert ] = hvFromArgs.apply( null, arguments );
      return new Bounds( this._.top + vert, this._.right + horz,
                                      this._.bot + vert, this._.left + horz );
  }
  subLoc( other ) {
      /* Non API */
      return this.offset( -other.getHorz(), -other.getVert() );
  }

  centerOn( loc, do_horz=true, do_vert=true ) {
      /**
      center the bounds on a location
      args:
          loc (Plane.Loc): the location to center on
          do_horz (bool): [opt = true] - center horizontally
          do_vert (bool): [opt = true] - center verticallyy
      return:
          Plane.Bounds: a new centered bounds
      */
      let hofst = do_horz ? -this._.left + loc.getHorz() - this.getWidth() / 2.0 : 0;
      let vofst = do_vert ? -this._.top + loc.getVert() - this.getHeight() / 2.0 : 0;
      return this.offset( hofst, vofst );
  }

  centerIn( rect, horz=true, vert=true ) {
      /**
      center the bounds in another bounds
      args:
          bounds (Plane.Bounds): the bounds to center in
          do_horz (bool): [opt = true] - center horizontally
          do_vert (bool): [opt = true] - center verticallyy
      return:
          Plane.Bounds: a new centered bounds
      */
      // center us in rect - rect is our enclosure (typically)
      return this.centerOn( rect.getCenter(), horz, vert );
  }

  fitIn( rect, horz=true, vert=true ) {
      // let fit_wid, fit_hgt = rect.getHeight() * this.aspect(), rect.getHeight();
      let fit_wid = rect.getHeight() * this.aspect();
      let fit_hgt = rect.getHeight();
      if( fit_wid > rect.getWidth() ) {
          // fit_wid, fit_hgt = rect.getWidth(), rect.getWidth() / this.aspect();
          fit_wid = rect.getWidth();
          fit_hgt = rect.getWidth() / this.aspect();
      }
      return new Bounds( fit_wid, fit_hgt ).centerIn( rect, horz, vert );
  }

  fitNicelyIn( rect, horz=true, vert=true ) {
      if( this.getWidth() > rect.getWidth() || this.getHeight() > rect.getHeight() ) {
          return this.fitIn( rect, horz, vert );
      } else {
          return this.centerIn( rect, horz, vert );
      }

  }
  inset( /*args*/ ) {
      /**
      change the size of us by moving our edges
      args:
          iinset (number): the amount to enset all edges
      OR
      args:
          iinset (Plane.Loc): the horizontal component specifies the distance
              to inset our left and right edges; the vertical component
              specifieds the distance to inset our top and bottom edges;
      OR
      args:
          iinset (Plane.Bounds): each edge of iinset specifies the distance to
              inset corresponding edge in us
      OR
      args:
          iinset_horz (number): the distance to inset our left and right edges
          iinset_vert (number): the distance to inset our top and bottom edges;
      OR
      args:
          iinset_top (number): the distance to inset our top edge
          iinset_right (number): the distance to inset our right edge
          iinset_bot (number): the distance to inset our bottom edge
          iinset_left (number): the distance to inset our left edge
      return:
          Plane.Bounds: a new inset bounds
      */
      let args = Util.arrayFromArgs( arguments );
      if( args.length === 4 ) {
          return new Bounds( this._.top + args[ 0 ], this._.right - args[ 1 ],
                                      this._.bot - args[ 2 ], this._.left + args[ 3 ] );
      } else {
          let [ horz, vert ] = hvFromArgs.apply( null, arguments );
          return new Bounds( this._.top + vert, this._.right - horz,
                                      this._.bot - vert, this._.left + horz );
      }

  }
  scale( factor, loc=null ) {
      /**
      change the size of us by scaling horizontally and or vertically
      args:
          scale (number): the amount to scale horizontally and vertically
          loc (Plane.Loc): [opt - our center] the point about scaling takes
      place
      OR
      args:
          scale (Plane.Loc): the horizontal component specifies the horizontal
      scale and the vertical component specifieds the vertical scale
          loc (Plane.Loc): [opt - our center] the point about scaling takes
      place
      OR
      args:
          iscale_horz (number): the horizontal scale
          iscale_vert (number): the vertical scale
          loc (Plane.Loc): [opt - our center] the point about scaling takes
      place
      return:
          Plane.Bounds: a new scaled bounds
      */
      if( loc === null ) {
          loc = this.getCenter();
      }
      // let hloc, vloc = loc.getHorz(), loc.getVert();
      let hloc = loc.getHorz();
      let vloc = loc.getVert();
      let scaled = new Bounds(
                  vloc - ( vloc - this._.top ) * factor,
                  hloc + ( this._.right - hloc ) * factor,
                  vloc + ( this._.bot - vloc ) * factor,
                  hloc - ( hloc - this._.left ) * factor );
      return scaled;
  }

  scaleEach( factor ) {
      let scaled = new Bounds(
                  this._.top * factor,
                  this._.right * factor,
                  this._.bot * factor,
                  this._.left * factor );
      return scaled;
  }


  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  union( other ) {
      /**
      args:
          other (Plane.Bounds): the bounds to union with
      return:
          Plane.Bounds: the unioned bounds
      */
      let union = new Bounds(
                  Math.min( this._.top, other._.top ),
                  Math.max( this._.right, other._.right ),
                  Math.max( this._.bot, other._.bot ),
                  Math.min( this._.left, other._.left ) );
      return union;
  }

  // @classmethod
  static unions( bounds ) {
      /* bounds is a list of Bounds objects
          NB returns None if bounds is empty + its ok for bounds to include None
              (makes it nicer for recursive usage) */
      bounds = bounds.filter( b => b );
      if( !bounds.length ) {
          return null;
      }
      let u = bounds[ 0 ];
      for( let b of bounds.slice( 1 ) ) {
          u = u.union( b );
      }
      return u;
  }

  sect( other ) {
      /**
      args:
          other (Plane.Bounds): the bounds to intersect with
          idefault (any): [opt] if the intersection is empty and this
              value is given, it is returned, otherwise an exception is thrown.
      return:
          Plane.Loc: the intersected bounds
      note:
          returns null if there is no intersecton between us and other
      */
      let sect = new Bounds(
                  Math.max( this._.top, other._.top ),
                  Math.min( this._.right, other._.right ),
                  Math.min( this._.bot, other._.bot ),
                  Math.max( this._.left, other._.left ) );
      // return null if sect.isEmpty() else sect;
      return sect.isEmpty() ? null : sect;
  }

  sectArea( other ) {
      /**
      args:
          other (Plane.Bounds): the bounds to intersect with
      return:
          number: the area of intersection between us and other
      note:
          returns 0 if there is no intersecton between us and other
      */
      let sect = this.sect( other );
      return sect ? sect.area() : 0;
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  containsLoc( loc ) {
      /**
      does loc lie within us?
      args:
          loc (Plane.Loc): the location to check
      return:
          bool: true if loc is inside us
              */
      var hloc = loc.getHorz(), vloc = loc.getVert();
      if( hloc < this._.left || hloc >= this._.right ) {
          return false;
      }
      if( vloc < this._.top || vloc >= this._.bot ) {
          return false;
      }
      return true;
  }
  pinLoc( loc ) {
      /**
      ensure loc lies within us
      args:
          loc (Plane.Loc): the location to pin
      return:
          Plane.Loc: the pinned location
              */
      var h = mmin( mmax( loc.getHorz(), this._.left ), this._.right );
      var v = mmin( mmax( loc.getVert(), this._.top ), this._.bot );
      return new Loc( h, v );
      }
  pinBounds( bounds ) {
      /**
      ensure bounds lies within us (if possible)
      args:
          bounds (Plane.Bounds): the bounds to pin
      return:
          Plane.Bounds: the pinned bounds

      NB attempts to pin first at the right / bottom and then at the left / top.
          So if bounds is too large to fit within us, it is postioned so its
          left / top is visible;
              */
      var new_bnds = bounds.copy();

      if( new_bnds._.right > this._.right ) {
          new_bnds = new_bnds.offset( this._.right - new_bnds._.right, 0 );
      }
      if( new_bnds._.bot > this._.bot ) {
          new_bnds = new_bnds.offset( 0, this._.bot - new_bnds._.bot );
      }
      if( new_bnds._.left < this._.left ) {
          new_bnds = new_bnds.offset( this._.left - new_bnds._.left, 0 );
      }
      if( new_bnds._.top < this._.top ) {
          new_bnds = new_bnds.offset( 0, this._.top - new_bnds._.top );
      }
      return new_bnds;
  }

  distanceToLoc( loc ) {
      /**
      return the distance between us and a loc

      args:
          iother (Plane.Loc): : the other location

      return:
          number: the distance
              */
      var part = this.getPart( loc, 0 );
      switch( part ) {
          case "LT":
              return loc.distanceToLoc( this.getLeftTop() );
          case "CT":
              return this._.top - loc.getVert();
          case "RT":
              return loc.distanceToLoc( this.getRightTop() );
          case "LC":
              return this._.left - loc.getHorz();
          case "CC":
              return 0;
          case "RC":
              return loc.getHorz() - this._.right;
          case "LB":
              return loc.distanceToLoc( this.getLeftBot() );
          case "CB":
              return loc.getVert() - this._.bot;
          case "RB":
              return loc.distanceToLoc( this.getRightBot() );
          default:
            throw new Error(`invalid part - ${part}`);
      }
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // handy when used for margins etc
  sumHorz() {
      /**
      return:
          number: the sum of our left and right values
      note:
          useful for when we are used to hold eg padding values
      */
      return this._.left + this._.right;
  }
  sumVert() {
      /**
      return:
          number: the sum of our top and bottom values
      note:
          useful for when we are used to hold eg padding values
      */
      return this._.top + this._.bot;
  }
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // "party" methods
  getPart( loc, edge_width ) {
      /**
      return a code describing where a location lies in relation to us
      args:
          loc (Plane.Loc): the location to compare to
          edge_width (number): the notional width of our edges
      return:
          string: the part code
      note:
           the returned part codes are as follows:
      "LT", "CT", "RT"
      "LC", "CC", "RC"
      "LB", "CB", "RB"
              */
      var hloc = loc.getHorz(), vloc = loc.getVert();
      var hpart, vpart;
      var inner = this.inset( edge_width );
      if( hloc < inner._.left ) {
          hpart = "L";
      } else if( hloc > inner._.right - 1 ) {
          hpart = "R";
      } else {
          hpart = "C";
      }
      if( vloc < inner._.top ) {
          vpart = "T";
      } else if( vloc > inner._.bot - 1 ) {
          vpart = "B";
      } else {
          vpart = "C";
      }
      return hpart + vpart;
  }
  getPartOffsets( loc, part ) {
      /**
      return the distance between a location and a part of us
      args:
          loc (Plane.Loc): the location to measure from
          part (string): a part code
      return:
          Plane.Loc: holds the horizontal and vertical distances
      note:
          for left, top edges, distance returned is -ve, which makes
          for more consistent handling when resizing rects
      note:
          for parts with a central h/v component the h/v offset
          is the same as the h/v loc
              */
      var hloc = loc.getHorz(), vloc = loc.getVert();
      var hofst = hloc, vofst = vloc;
      switch( part ) {
          case "LT": case "LC": case "LB":
              hofst = this._.left - hloc;
          break;
          case "RT": case "RC": case "RB":
              hofst = this._.right - hloc;
          break;
          default:
            throw new Error(`invalid part - ${part}`);
      }
      switch( part ) {
          case "LT": case "CT": case "RT":
              vofst = this._.top - vloc;
          break;
          case "LB": case "CB": case "RB":
              vofst = this._.bot - vloc;
          break;
          default:
            throw new Error(`invalid part - ${part}`);
      }
      return new Loc( hofst, vofst );
  }
  resizeByPartLoc( loc, part ) {
      /**
      resize us based on a location and a part code
      args:
          loc (Plane.Loc): holds the horizontal / vertical location to resize to
          part (string): a part code specifying our edge / corner
      return:
          Plane.Bounds: a new resized bounds
              */
      var hloc = loc.getHorz(), vloc = loc.getVert();
      var new_bnds = this.copy();  // NB altered "in-place"
      switch( part ) {
          case "LT":
              new_bnds.setLeft( hloc );
              new_bnds.setTop( vloc );
          break;
          case "LC":
              new_bnds.setLeft( hloc );
          break;
          case "LB":
              new_bnds.setLeft( hloc );
              new_bnds.setBot( vloc );
          break;
          case "CT":
              new_bnds.setTop( vloc );
          break;
          case "CB":
              new_bnds.setBot( vloc );
          break;
          case "CC":
              throw new Error( "invalid part code - " + part );
          // break;
          case "RT":
              new_bnds.setRight( hloc );
              new_bnds.setTop( vloc );
          break;
          case "RC":
              new_bnds.setRight( hloc );
          break;
          case "RB":
              new_bnds.setRight( hloc );
              new_bnds.setBot( vloc );
          break;
          default:
            throw new Error(`invalid part - ${part}`);
      }
      return new_bnds;
  }
  resizeByPartDist( dist, part ) {
      /**
      resize us based on horizontal / vertical distances and a part code
      args:
          iloc (Plane.Loc): holds the horizontal / vertical distances to resize by
          part (string): a part code specifying our edge / corner
      return:
          Plane.Bounds: a new resized bounds
              */
      var hdist = dist.getHorz(), vdist = dist.getVert();
      var new_bnds = this.copy();  // NB altered "in-place"
      switch( part ) {
          case "LT":
              new_bnds.setLeft( this._.left + hdist );
              new_bnds.setTop( this._.top + vdist );
          break;
          case "LC":
              new_bnds.setLeft( this._.left + hdist );
          break;
          case "LB":
              new_bnds.setLeft( this._.left + hdist );
              new_bnds.setBot( this._.bot + vdist );
          break;
          case "CT":
              new_bnds.setTop( this._.top + vdist );
          break;
          case "CB":
              new_bnds.setBot( this._.bot + vdist );
          break;
          case "CC":
              throw new Error( "invalid part code - " + part );
          // break;
          case "RT":
              new_bnds.setRight( this._.right + hdist );
              new_bnds.setTop( this._.top + vdist );
          break;
          case "RC":
              new_bnds.setRight( this._.right + hdist );
          break;
          case "RB":
              new_bnds.setRight( this._.right + hdist );
              new_bnds.setBot( this._.bot + vdist );
          break;
          default:
            throw new Error(`invalid part - ${part}`);
      }
      return new_bnds;
  }

  alignByPartIn( bnds, part ) {
      /* part can be "L", "R", "T", "B" - or "LT", "LC" etc */
      if( part === "L" ) {
          return this.offset( bnds._.left - this._.left, 0 );
      }
      if( part === "LT" ) {
          return this.offset( bnds._.left - this._.left, bnds._.top - this._.top );
      }
      if( part === "T" ) {
          return this.offset( 0, bnds._.top - this._.top );
      }
      if( part === "RT" ) {
          return this.offset( bnds._.left - this._.left + ( bnds.getWidth() - this.getWidth() ), bnds._.top - this._.top );
      }
      if( part === "R" ) {
          return this.offset( bnds._.left - this._.left + ( bnds.getWidth() - this.getWidth() ), 0 );
      }
      if( part === "RB" ) {
          return this.offset( bnds._.left - this._.left + ( bnds.getWidth() - this.getWidth() ),
                              bnds._.top - this._.top + ( bnds.getHeight() - this.getHeight() ) );
      }
      if( part === "B" ) {
          return this.offset( 0, bnds._.top - this._.top + ( bnds.getHeight() - this.getHeight() ) );
      }
      if( part === "LB" ) {
          return this.offset( bnds._.left - this._.left,
                              bnds._.top - this._.top + ( bnds.getHeight() - this.getHeight() ) );
      }
      throw new Exception.Exception( "invalid part code - " + part );
  }

  toJSON() {
      /* Non API */
      return this._;
  }
}



// -------------------------------------------------------------------------------------------------
// given different arg counts / types, return horz and vert values
function hvFromArgs( /*args*/ ) {
    let args = Util.arrayFromArgs( arguments );
    let nargs = args.length;
    let h, v;
    if( nargs === 0 ) {
        h = v = 0;
    } else if( nargs === 1 ) {
        let arg = args[ 0 ];
        if( Util.isNumber( arg ) ) {
            h = v = arg;
        } else if( Util.isInstance( arg, Loc ) ) {
            h = arg._.horz;
            v = arg._.vert;
        } else if( Util.hasAttr( arg, "horz" ) && Util.hasAttr( arg, "vert" ) ) {
            h = arg.horz;
            v = arg.vert;
        } else if( arg.length === 2 ) {
            h = arg[ 0 ];
            v = arg[ 1 ];
        } else {
            throw new Exception.TypeError( `invalid argument type (hv) - ${ JSON.stringify( args ) }` );
        }
    } else if( nargs === 2 ) {
        h = args[ 0 ];
        v = args[ 1 ];
    } else {
        throw new Exception.TypeError( `invalid argument count (hv) - ${ JSON.stringify( args ) }` );
    }
    return [ h, v ];
}


// given different arg counts / types, return top, right, bot, left values
function trblFromArgs( /*args*/ ) {
    let args = Util.arrayFromArgs( arguments );
    let nargs = args.length;
    let t, r, b, l;
    if( nargs === 0 ) {
        t = r = b = l = 0;
    } else if( nargs === 1 ) {
        let arg = args[ 0 ];
        if( Util.isNumber( arg ) ) {
            t = l = 0;
            r = b = arg;
        } else if( Util.isInstance( arg, Loc ) ) {
            t = l = 0;
            r = arg._.horz;
            b = arg._.vert;
        } else if( Util.isInstance( arg, Bounds ) ) {
            t = arg._.top;
            r = arg._.right;
            b = arg._.bot;
            l = arg._.left;
        } else if( Util.hasAttr( arg, "width" ) && Util.hasAttr( arg, "height" ) ) {
            t = l = 0;
            r = arg.width;
            b = arg.height;
        } else if( Util.hasAttr( arg, "t" ) && Util.hasAttr( arg, "r" ) ) {  // don't check them all
            t = arg.t;
            r = arg.r;
            b = arg.b;
            l = arg.l;
        } else if( Util.hasAttr( arg, "top" ) && Util.hasAttr( arg, "right" ) ) {  // don't check them all
            t = arg.top;
            r = arg.right;
            b = arg.bot;
            l = arg.left;
        } else if( arg.length === 4 ) {
            t = arg[ 0 ];
            r = arg[ 1 ];
            b = arg[ 2 ];
            l = arg[ 3 ];
        } else {
            throw new Exception.TypeError( `invalid argument type (trbl 1) - ${ JSON.stringify( args ) }` );
        }
    } else if( nargs === 2 ) {
        // let arg0, arg1 = args[ 0 ], args[ 1 ];
        let arg0 = args[ 0 ];
        let arg1 = args[ 1 ];
        if( Util.isNumber( arg0 ) ) {
            t = l = 0;
            r = arg0;
            b = arg1;
        } else if( Util.isInstance( arg0, Loc ) ) {
            // top_left / bot_right
            t = arg0.getVert();
            r = arg1.getHorz();
            b = arg1.getVert();
            l = arg0.getHorz();
        } else {
            throw new Exception.TypeError( `invalid argument type (trbl 2) - ${ JSON.stringify( args ) }` );
        }
    } else if( nargs === 4 ) {
        t = args[ 0 ];
        r = args[ 1 ];
        b = args[ 2 ];
        l = args[ 3 ];
    } else {
        throw new Exception.TypeError( `invalid argument count (trbl) - ${ JSON.stringify( args ) }` );
    }
    return [ t, r, b, l ];
}
