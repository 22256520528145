import * as Browser from './Browser';
import * as Exception from './Exception';
import * as LOG from './LOG';
import * as Util from './Util';

class PaneNotFoundErr extends Exception.Exception {}
class EncPaneNotFoundErr extends PaneNotFoundErr {}
class OutPaneNotFoundErr extends PaneNotFoundErr {}

const fakeGlazier = {
    getPane: elem => new Pane(elem),
    isFakeElem: elem => false,
}


// -------------------------------------------------------------------------------------------------
export class Pane {

    constructor(elem) {
        this._elem = elem;
    }
    elem() {
        return this._elem;
    }

    getService(name) {
        if (name === 'glazier') {
            return fakeGlazier;
        }
        throw new Exception.Exception(`invalid service - '${name}`);
    }

    // ---------------------------------------------------------------------------------------------
    // panes-outwith access

    getPage() {
        /**
        return:
            Pane: the outermost pane on the page (all sub-panes reside in this)
        */
        return this.getService( 'glazier' ).getMPPane();
    }
    isPage() {
        return this._path === "~";
    }

    getEncPane( fallback, ielem=null ) {
        /**
        args:
            ielem (element): this argument is only used internally by classes that
                work with iframes
        return:
            Pane: the pane that encloses us
            null: if we are the glazier's elem

        NB the ielem argument is only used by iframe subclasses which override
            this method and use a different element from that returned by elem()
        NB this method ignores fake panes
        */
        if( this._path !== "~" ) {
            var elem = ielem || this.elem();
            var glaz = this.getService( 'glazier' );
            while( ( elem = Browser.elem.encElem( elem ) ) ) {
                if( !glaz.isFakeElem( elem ) ) {
                    return glaz.getPane( elem );
                }
            }
        }
        if( Util.isDef( fallback ) ) {
            return fallback;
        }
        throw new EncPaneNotFoundErr();
    }

    getEncPanes( elem=null ) {
        /**
        NB see notes in getEncPane() re elem
        NB this method ignores fake panes
        */
        elem = elem || this.elem();
        var glaz = this.getService( 'glazier' );
        var enc_elems = Browser.elem.encElems( elem ).filter( elem => !glaz.isFakeElem( elem ) );
        var enc_panes = enc_elems.map( function( elem ) {
            return this.getService( 'glazier' ).getPane( elem );
        }.bind( this ) );
        return enc_panes;
    }

    getOutPanes() {
        /**
        return all enclosing panes (including us)
        */
        var out_panes = this.getEncPanes();
        out_panes.unshift( this );
        return out_panes;
    }

    findOutPane( test, fallback ) {
        /**
        find an outer pane that matches a condition
        args:
            test (string | class | function): see getEncPanes()
            fallback (any): [opt] if a pane matchng test is not found and this
                value is given, it is returned, otherwise an exception is thrown.
        return:
            Pane: the outer pane that matches test
        */
        test = makePaneFilter( test );
        var pane = Util.list.detect( this.getOutPanes(), function( pane ) {
            return test( pane ) && pane;
        } );
        if( pane ) {
            return pane;
        }
        if( Util.isDef( fallback ) ) {
            return fallback;
        }
        throw new OutPaneNotFoundErr( this.getDestPath() );
    }

    acquireAttr( name, fallback) {
        /* get a value by name from us or any of our containing panes.
            NB we search properties, direct pane attributes and element attributes
                in that order */
        var val = Util.list.detect( this.getOutPanes(), function( pa ) {
            var v = pa.getValueForAcquire( name );
            if( Util.isDef( v ) ) {
                return v;
            }
        } );
        if( Util.isDef( val ) ) {
            return val;
        }
        if( Util.isDef( fallback ) ) {
            return val;
        }
        throw new Error( "cannot acquire value " + name );
    }

    getValueForAcquire( name ) {
        var sent = { a: 1 };
        var val = this.getPropVal( name, sent );
        if( !Object.is( val, sent ) ) {
            return val;
        }
        if( this.hasOwnProperty( name ) ) {
            return this[ name ];
        }
        val = this.getAttr( name, sent );
        if( !Object.is( val, sent ) ) {
            return val;
        }
    }

    // ---------------------------------------------------------------------------------------------
    // panes-within access

    $( path, fallback) {
        /**
        access a sub-pane within us
        args:
            path (string): the path to the pane, or just its name
            fallback (any): [opt] if a pane at path is not found and this
                value is given, it is returned, otherwise an exception is thrown.
        return:
            Pane: the pane at path
        note:
            this is a shorthand name for getSubPane() which this method calls.
        */
        return this.getSubPane( path, fallback );  // search for elem in us
    }

   getSubPane( path, fallback) {
       /**
       access a sub-pane within us
       args:
           path (string): the path to the pane, or just its name
           fallback (any): [opt] if a pane at path is not found and this
               value is given, it is returned, otherwise an exception is thrown.
       return:
           Pane: the pane at path
       note:
           the $() method calls this
        */
        LOG.ASSERT( !path.startsWith( "~" ), "pane path must NOT start with '~' - " + path );

        var found_elem = Browser.elem.findSubElemByPath( this.elem(), path );
        if( found_elem ) {
            // return this.getService( 'glazier' ).getPane( found_elem );
            return new Pane( found_elem );
        }
        if( Util.isDef( fallback ) ) {
            return fallback;
        }
        throw new PaneNotFoundErr( path );
    }

    getChildPane( name, fallback ) {
        let childs = Browser.elem.childElems( this.elem() ).filter( e =>
                                    Browser.elem.readAttr( e, 'name' ) === name );
        if( childs.length ) {
            return this.getService( 'glazier' ).getPane( childs[ 0 ] );
        }
        if( Util.isDef( fallback ) ) {
            return fallback;
        }
        throw new PaneNotFoundErr( name );
    }

    getChildPanes() {
        /**
        get our immediate descendents
        return:
            array: all child panes in us
        */
        return this._rawElemsToPanes( Browser.elem.childElems( this.elem() ) );
    }

    getSubPanesB() {
        /**
        get all our sub-panes - breadth-first order
        return:
            array: all sub-panes in us breadth-first order
        */
    return this._rawElemsToPanes( Browser.elem.subElemsB( this.elem() ) );
    }

    getSubPanesD() {
        /**
        get all our sub-panes - depth-first order
        return:
            array: all sub-panes in us breadth-first order
        */
    return this._rawElemsToPanes( Browser.elem.subElemsD( this.elem() ) );
    }

    getSubPanes() {
        /**
        this is the same as getSubPanesB
        */
        return this.getSubPanesB();
    }

    _rawElemsToPanes( raw_elems ) {
        /*
        NON-API
        */
       return raw_elems.map( el => new Pane( el ) );
        // var glaz = this.getService( 'glazier' );
        // var panes = raw_elems.map( function( el ) {
        //     try {
        //         return glaz.getPane( el );
        //     } catch( e ) {
        //         LOG.WARN( e );
        //         return glaz.getFleetingVanillaPane( el );
        //     }
        // }, this );
        // return panes;
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // containment

    within( pane ) {
        /**
        are we contained by pane?
        args:
            pane (Pane): the pane we might be within
        return:
            bool: true if we are within pane
        note:
            for convenience, pane may be null in which case we return false
            (for eg if( event.within( this.$( "name", null ) ) {....)
        */
        if( !pane ) {
            return false;
        }
        var our_path = this.getDestPath(), oth_path = pane.getDestPath();
        return this.getService( 'glazier' ).mpPathStarts( our_path, oth_path );
    }

    outerMost( pane ) {
        /**
        return the pane - either us or pane - which is closest to the root of
        the page.
        args:
            pane (Pane): the pane to compare against
        return:
            Pane: the outermost pane
        */
        if( this.within( pane ) ) {
            return pane;
        }
        if( pane.within( this ) ) {
            return this;
        }
        throw new Error( "invalid containment - " + pane.getDestPath() + " / " + this.getDestPath() );
    }

    innerMost( pane ) {
        /**
        return the pane - either us or pane - which is furthest from the root of
        the page.
        args:
            pane (Pane): the pane to compare against
        return:
            Pane: the outermost pane
        */

        // return this.outerMost( pane ) === this ? pane : this;
        return this.outerMost( pane ).equals( this ) ? pane : this;
    }
    
    // ---------------------------------------------------------------------------------------------
    // location and bounds handling - all in pixels!

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // location handling

    /* relative to itself - always (0,0) */
    /* getLoc(){}, */

    offsetLoc( dist ) {
      /**
      change our location by distance
      args:
          dist (Plane.Loc): the distance to move
      */
      var pmode = this.css( "position" );
      if( pmode === "static" || pmode === "relative" || pmode === "sticky" ) {
          var fmode = this.css( "float" );
          var mrgs = this.getMargins();
          if( fmode === "right" ) {
              mrgs.setRight( mrgs.getRight() - dist.getHorz() );
          } else {
              mrgs.setLeft( mrgs.getLeft() + dist.getHorz() );
          }
          mrgs.setTop( mrgs.getTop() + dist.getVert() );
          this.setMargins( mrgs );
      } else if( pmode === "absolute" || pmode === "fixed" ) {
          var left_top = this.css( "left top" );
          this.css( {
              left: parseFloat( left_top.left ) + dist.getHorz() + "px",
              top: parseFloat( left_top.top ) + dist.getVert() + "px",
          } );
      }
  }

  getLocEnc() {
      /**
      return:
          Plane.Loc: our location relative to our enclosing pane
      */
      return Browser.elem.getLocEnc( this.elem() );
  }

  setLocEnc( loc ) {
      /**
      set our location relative to our enclosing pane
      args:
          loc (Plane.Loc): the location to set
      */
      var pmode = this.css( "position" );
      if( pmode === "static" || pmode === "relative" ) {
          var enc_pane = this.getEncPane();
          var enc_pads = enc_pane.getPaddings();
          var mrgs = this.getMargins();
          mrgs.setTop( loc.getVert() - enc_pads.getTop() );
          mrgs.setLeft( loc.getHorz() - enc_pads.getLeft() );
          this.setMargins( mrgs );
             // FIXME - for abs positioning, enc_loc should prob be loc of rel-pos'd ele
      } else if( pmode === "absolute" || pmode === "fixed" ) {
          this.css( { left: loc.getHorz() + "px", top: loc.getVert() + "px" } );
      } else {
          var msg = "invalid css position mode for setting location - ";
          console.warn( msg + pmode + " - " + this.toString() );
      }
  }


  getLocPage() {
      /**
      return:
          Plane.Loc: our location relative to the page that we are in
      */
      return Browser.elem.getLocPage( this.elem() );
  }

  setLocPage( loc ) {
      /**
      set our location relative to the page we are in
      args:
          loc (Plane.Loc): the location to set
      */
      var enc_pane = this.getEncPane();
      var enc_loc = enc_pane.getLocPage();
      this.setLocEnc( loc.subLoc( enc_loc ) );
  }

  getLocWin() {
      /**
      return:
          Plane.Loc: our location relative to the browser window
      */
    //   return Browser.wind_port.getScroll().diffLoc( this.getLocPage() );
      return Browser.wind_port.getScroll().subLoc( this.getLocPage() );
  }

  setLocWin( loc ) {
      /**
      set our location relative to the window
      args:
          loc (Plane.Loc): the location to set
      */
      loc = Browser.elem.adjustWinCoords( loc, this.elem() );

      var pos = this.css( "position" );
      if( pos === "fixed" ) {
          this.css( { left: loc.getHorz() + "px", top: loc.getVert() + "px" } );

      } else if( pos === "absolute" ) {
          // 190310 - fixed this - do not change
          var enc_pane = this.getEncPane();
          var enc_loc = enc_pane.getLocWin();
          this.setLocEnc( loc.subLoc( enc_loc ) );
      } else {
          this.setLocPage( loc.addLoc( Browser.wind_port.getScroll() ) );
      }
  }

  getLocRel( rel ) {
      /**
      args:
          ipane (Pane) the pane whose location the location is relative to
      return:
          Plane.Loc: our location relative to another pane
      */
      return rel.getLocPage().diffLoc( this.getLocPage() );
      }

  setLocRel( rel, loc ) {
      /**
      set our location relative to another pane
      args:
          rel (Pane) the pane to set the location relative to
          loc (Plane.Loc): the location to set
      */
      this.setLocPage( loc.addLoc( rel.getLocPage() ) );
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // bounds handling

  // convenient and efficient
  setWidth( wid, units ) {
      /**
      set our width (in pixels)
      args:
          wid (number): the width to set
          units (string): (opt) the units "px", "%", "em" or "rem"
      */
      this.css( { width: wid + ( units || "px" ) } );
  }

  setHeight( hgt, units ) {
      /**
      set our height (in pixels)
      args:
          iwid (number): the width to set
          units (string): (opt) the units "px", "%", "em" or "rem"
      */
      this.css( { height: hgt + ( units || "px" ) } );
  }


  getBounds() {
      /**
      return:
          Plane.Bounds: our bounds (left-top = (0,0)
      */
      return Browser.elem.getBounds( this.elem() );
  }

  setBounds( bounds ) {
      /**
      set our bounds
      args:
          bounds (Plane.Bounds): the bounds to set
      note:
          this method will not alter the location of the pane - it ignores
          the top left of bounds and just uses the width and height.
          NB this method sets the "actual" size of the pane as it appears in the
              browser, and as returned by getBounds(). The width / height of this
              may differ from the css width / height depending on paddingm
      */
      var props = this.css( "box_sizing padding border_width" );
      if( props.box_sizing !== "border-box" ) {
          bounds = bounds.inset( Browser.elem.style.strToBounds( props.padding ) );
          bounds = bounds.inset( Browser.elem.style.strToBounds( props.border_width ) );
      }
      this.css( { width: bounds.getWidth() + "px", height: bounds.getHeight() + "px" } );
  }

  getBoundsEnc() {
      /**
      return:
          Plane.Bounds: our bounds relative to our enclosing pane
      */
      return this.getBounds().offset( this.getLocEnc() );
  }

  setBoundsEnc( bounds ) {
      /**
      set our bounds relative to our enclosing pane
      args:
          bounds (Plane.Bounds): the bounds to set
      */
      this.setBounds( bounds );
      this.setLocEnc( bounds.getLeftTop() );
  }

  getBoundsPage() {
      /**
      return:
          Plane.Bounds: our bounds relative to the page
      */
      var bnds_page = Browser.elem.getBoundsPage( this.elem() );
      return bnds_page;
      // return this.getService( 'glazier' ).offsetToPage( this, bnds_page );
  }

  setBoundsPage( bounds ) {
      /**
      set our bounds relative to the page
      args:
          bounds (Plane.Bounds): the bounds to set
      */
      this.setBounds( bounds );
      this.setLocPage( bounds.getLeftTop() );
  }

  getBoundsWin() {
      /**
      return:
          Plane.Bounds: our bounds relative to the window
      note:
          the returned bounds depends on the window's scroll
      */
      return this.getBounds().offset( this.getLocWin() );
  }

  setBoundsWin( bounds ) {
      /**
      set our bounds relative to the window
      args:
          bounds (Plane.Bounds): the bounds to set
      note:
          our resulting bounds depends on the window's scroll
      */
      this.setBounds( bounds );
      this.setLocWin( bounds.getLeftTop() );
  }

  getBoundsRel( rel ) {
      /**
      args:
          ipane (Pane) the pane whose location the bounds are relative to
      return:
          Plane.Bounds: our bounds relative to another pane
      */
      return this.getBounds().offset( this.getLocRel( rel ) );
  }

  setBoundsRel( rel, bounds ) {
      /**
      set our bounds relative to the window
      args:
          rel (Pane) the pane to set the bounds relative to
          bounds (Plane.Bounds): the bounds to set
      */
      this.setBounds( bounds );
      this.setLocRel( rel, bounds.getLeftTop() );
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // margins / padding
  getMargins() {
      /**
      return:
          Plane.Bounds: our margins
      */
      return Browser.elem.style.strToBounds( this.css( "margin" ) );
  }
  setMargins( margins ) {
      /**
      args:
          margins (Plane.Bounds): the margins to set
      */
      this.css( "margin", Browser.elem.style.boundsToStr( margins ) );
  }
  getPaddings() {
      /**
      return:
          Plane.Bounds: our paddings
      */
      return Browser.elem.style.strToBounds( this.css( "padding" ) );
  }
  setPaddings( paddings ) {
      /**
      args:
          paddings (Plane.Bounds): the paddings to set
      */
      this.css( "padding", Browser.elem.style.boundsToStr( paddings ) );
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // scrollerama
  getScroll() {
      /**
      return:
          Plane.Loc: our scroll
      */
      return Browser.elem.getScroll( this.elem() );
  }
  setScroll( scroll ) {
      /**
      args:
          scroll (Plane.Loc): the scroll to set
      */
      Browser.elem.setScroll( this.elem(), scroll );
  }
  getScrollMax() {
      /**
      return:
          Plane.Loc: our maximum scroll values (horizontal and vertical)
      */
      return Browser.elem.getScrollMax( this.elem() );
  }

  getBestHeight() {
      /**
      return:
          number: the height such that all elements within us would be visible
      */
      return Browser.elem.getScrollHeight( this.elem() );
  }


  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  // "display" handling
  isDisplayed() {
      /**
      are we displayed?
      return:
          bool: true if the pane's display css property is anything other than "none"
      */
      return this.css( "display" ) !== "none";
  }
  setDisplay( disp ) {
      /**
      set whether we are displayed
      args:
          disp (bool): whether the pane is to be displayed

          OR

          disp (string): the value to be used for the display
              css property
      */
      if( Util.isString( disp ) ) {
          disp = disp || "none";        // empty string means none
      } else {
          disp = disp ? "block" : "none";
      }
      this.css( "display", disp );
  }

  isRealVisible() {
      /**
      are we really visible?
      return:
          bool: true if the pane and all its containers are visible
      */
      return Browser.elem.isRealVisible( this.elem() );
  }
    // ---------------------------------------------------------------------------------------------
    /* style handling */
    /* style items as coerced values
        style( "width:px" ) => 100
        style( "width height" ) => { width: "100px", height: "200px" }
        style( "width", "100px" ) => sets width to "100px"
        style( "width:px", 100 ) => sets width to "100px"
        style( { width: "100px" height: "200px" } ) => sets width to "100px", height to "200px"
    */
    style( arg1, arg2 ) {
        throw new Error( "not yet" );
    }

    /* deprecated */
    styleRaw( arg1, arg2 ) {
        return this.css( arg1, arg2 );
    }

    css( arg1, arg2 ) {
        /**
        get or set css properties of the pane
        args:
            arg1 (string or object): see examples
            arg2 (string or object): see examples
        return:
            string or object: one or more css values

        example:

            apane.css( "width" ) => "100px"

            apane.css( "width height" ) => { width: "100px", height: "200px" }

            apane.css( "width", "100px" ) => sets width to "100px"

            apane.css( { width: "100px", height: "200px" } ) => sets width to "100px", height to "200px"
        */
        var elem = this.elem();
        if( Util.isDef( arg2 ) ) {
            Browser.elem.style.writeRaw( elem, arg1, arg2 );
        } else if( Util.isObject( arg1 ) ) {
            Browser.elem.style.writeRaw( elem, arg1 );
        } else {
            if( Util.isString( arg1 ) ) {
                arg1 = Util.string.splitWords( arg1 );
            }
            var vals = Browser.elem.style.readRaw( elem, arg1 );
            if( arg1.length === 1 ) {
                return vals[ arg1[ 0 ] ];
            }
            return vals;
        }
    }

    hasLocalStyleProp( name ) {
        /**
        does our local style have the given property
        args:
            name (string): the css property name (camelCase if nnecessary)
        return:
            bool: tru if our local style has the property
        */
        var lstyle = this.readElemAttr( "style", null );
        return lstyle && Util.string.contains( lstyle, name + ":" );
    }

    // ---------------------------------------------------------------------------------------------
    /* style name handling */
    hasStyleName( name ) {
        /**
        do we have the given style name?
        args:
            name (string): a css style name
        return:
            bool: true if we have the style applied to us
        */
        return Browser.elem.style.names.has( this.elem(), name );
    }
    addStyleName( name ) {
        /**
        add a style name to us
        args:
            name (string): the style name to add
        */
        Browser.elem.style.names.add( this.elem(), name );
    }
    rmvStyleName( name ) {
        /**
        remove a style name from us
        args:
            name (string): the style name to remove
        */
        Browser.elem.style.names.rmv( this.elem(), name );
    }
    condStyleName( cond, name ) {
        /**
        add the given style name to us if a condition is truthy
        args:
            cond (any): if this is truthy, the style will be added to us
            name (string): the style name to add
        */
        Browser.elem.style.names.cond( this.elem(), cond, name );
    }
    eitherStyleName( cond, first, second ) {
        /**
        add the one or other style name to us based on a condition
        args:
            cond (any): if this is truthy, the first style will be added
        to us
            iname (string): the style name to add
        */
        Browser.elem.style.names.either( this.elem(), cond, first, second );
    }
    choiceStyleName( sname, snames ) {
        /**
        set us to have one of a set of style names
        args:
            sname (string): a style name, or a key into snames
            snames (array or object): the style names to choose from

        snames can be an array of possible styles only one of which - sname -
        we want applied to the elemen. Or it can be a mapping of arbitrary
        strings to style names, and sname is a key into that mapping.

        note:
            only one of the choice style names is applied to the pane - all
            other choice style names are removed
        */
        Browser.elem.style.names.choice( this.elem(), sname, snames );
    }
    toggleStyleName( sname ) {
        /**
        add the style if its not present or remove it if it is
        args:
            sname (string): the style name to toggle
        */
        this.condStyleName( !this.hasStyleName( sname ), sname );
    }
    clearStyleNames() {
        /**
        remove all style names from us
        */
        Browser.elem.style.names.clear( this.elem() );
    }

    getStyleNames() {
        /**
        return:
            array: contains all style names currently applied to us
        */
        return Browser.elem.style.names.all( this.elem() );
    }

//     // ---------------------------------------------------------------------------------------------
//     readElemAttr( name, fallback) {
//         /**
//         deprecated - use getAttr()
//         */
//         return this.getAttr( name, fallback );
//     }
//
//     writeElemAttr( name, val ) {
//         /**
//         deprecated - use setAttr()
//         */
//         return this.setAttr( name, val );
//     }
//
    getAttr( name, fallback) {
        /**
        return an attribute from our element
        args:
            name (string): the attribute name
            fallback (any):  [opt] if the attribute does not exist and this value is
                given, it is returned, otherwise an exception is thrown.
        return:
            string: the attribute value
        */
        return Browser.elem.readAttr( this.elem(), name, fallback );
    }

    setAttr( name, val ) {
        /**
        set an attribute on our element
        args:
            name (string): the attrbute name
            val (string): the attribute value
        */
        /* FIXME - should take an object aswell to allow mutliple sets at once */
        Browser.elem.writeAttr( this.elem(), name, val );
        // defer if( name.startsWith( "data-" ) && name.endsWith( "-url" ) ) {
        // defer     this.getService( 'ajaxer' ).doServiceCall( "/core/pstate", "getFixedUrl", {
        // defer         iurl: val,
        // defer         fallback: null
        // defer     },
        // defer     function( reply ) {
        // defer         if( reply.result ) {
        // defer             /* NB currently all "data-..." attrs map to real "src" attr */
        // defer             Browser.elem.writeAttr( this.elem(), "src", reply.result );
        // defer         } else {
        // defer             throw new Error( "cannot fix url " + val + " for " + name );
        // defer         }
        // defer     }.bind( this ) );
        // defer }
    }  
}

/*- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -*/
function makePaneFilter( filter ) {
    /* semi-funky - attempts to deal with multiple filter types
    NB name-based filtering is no good for repeats!
    NB FIXME - could make name based filtering use a regex */

    if( !filter ) {
        return function( pane ) {
            return true;
        };
    }

    if( Util.isClass( filter ) ) {
        return function( pane ) {
            return Util.isInstance( pane, filter );
        };
    }

    if( Util.isString( filter ) ) {
        if( filter[ 0 ] === "$" ) {
            return function( pane ) {
                return pane.name() === filter.substring( 1 );
            };

        } else if( filter[ 0 ] === "." ) {
            return function( pane ) {
                return pane.hasStyleName( filter.substring( 1 ) );
            };

        } else {
            return function( pane ) {
                return pane.html_tag() === filter;
            };
        }
    }

    if( Util.isFunc( filter ) ) {
        return filter;
    }
    throw new Error( "invalid pane filter - " + filter );
}
