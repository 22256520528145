import React, {useState} from "react";
import PropTypes from 'prop-types';

export const HXTextEntry = (props) => {
  const [enteredText, setEnteredText] = useState(props.enteredText);

  const handleTextChange = event => {
    const enteredText = event.target.value;
    setEnteredText(enteredText)
    props.onChange(enteredText);
  }

  return (
    <input name="value"
      value={enteredText}
      data-cons="core.Widget.TextEntry({},null,{'iname':'value','service':'/core/pstate','ivalue':'__value__','ipath':'COMMON.filter.contextual_diversity','invals':['~page~controls~cd','~table~sheet'],'method':'setPState'})"
      className="value-entry -hx-text-entry" type="text"
      onChange={handleTextChange} />
  )
}

HXTextEntry.propTypes = {
  enteredText: PropTypes.string,
  onChange: PropTypes.func,
}

