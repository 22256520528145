import bilexLogo from '../images/BILEX-LOGO-3-1.png';
import face04 from '../images/faces/face-04.svg'
import face06 from '../images/faces/face-06.svg'
import face08 from '../images/faces/face-08.svg'
import face10 from '../images/faces/face-10.svg'

export function BXHomePage() {
  return (
    <div
      name="page_contents"
      className="page-contents"
      style={{backgroundColor:'#4ec2ff'}}
    >
      <div
        className="vert-space-between"
        style={{height:'60%',}}
      >
        <div
          style={{display:'flex',flexDirection:'row',height:'120px',justifyContent:'center',minWidth:'160px',width:'100%'}}
        >
          <img
            style={{width:'200px',}}
            src={bilexLogo}
            alt=""
          />
        </div>
        <div
          style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly',width:'100%',}}
        >
          {/* LINK */}
          <a href="/#/bilex-kids/select-language">
            <div className="home-menu-button-inner -mp-lay-fl">
              <img
                style={{height:'123.25px',width:'111px',}}
                src={face08}
                alt=""
              />
              <img style={{height:'123.25px',width:'111px',}}
                src={face06}
                alt=""
              />
              </div>
            <div
              className="home-menu-button-text">
              Bilex Kids
            </div>
          </a>
           {/* LINK */}
          <a href="/#/bilex-plus/menu">
            <div
              className="home-menu-button-inner -mp-lay-fl">
              <img
                style={{height:'121.242px',width:'87px',}}
                src={face10}
                alt=""
              />
              <img
                style={{height:'120.936px',width:'87px',}}
                src={face04}
                alt=""
              />
            </div>
            <div
              style={{color:'#1f0993',fontSize:'24px',fontWeight:'bolder',textAlign:'center',}}>
              Bilex Plus
            </div>
          </a>
        </div>
        <div
          style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly',width:'100%',}}
        >
          <p
            style={{color:'#ffffff',fontSize:'33px',lineHeight:'60px',textAlign:'center',}}>
            Welcome!<br/>
            καλώς ήρθατε
          </p>
        </div>
      </div>
    </div>
  );
}
