
import Papa from 'papaparse';

export const csvToObjects = (csvData) => {
  const { data } = Papa.parse(
    csvData, {
      worker: false,
      header: true,
      dynamicTyping: true,
  });
  return data;
}