import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";
import * as constants from '../constants';

import { BXPlusHeader } from '../ui/BXPlusHeader';

import plusMainMenuBackground from '../images/plus-main-menu-background.png';

export function BXPlusMenuPage() {
  const selectedLanguage = useSelector(state => state.selectedLanguage);

  let className = 'page-contents dark -mp-lay-fl ';
  className += (selectedLanguage === 'EL') ? 'EL-ACTIVE' : 'EN-ACTIVE';

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goToDataWithLevel = (selectedLevel) => {
    const dataFileName = constants.LEVEL_TO_DATAFILE_NAME[selectedLevel];
    dispatch({type: 'dataFileName', payload: dataFileName});
    /* LINK */
    navigate('/bilex-plus/data');
  }
  return (
    <div
      name="page_contents"
      className={className}
    >
      <div
        className="vert-flex-start">
        <div
          name="slot"
          style={{flexGrow:1,minHeight:'160px',minWidth:'100px',position:'relative',margin:'0px',}}
        >

          <BXPlusHeader/>

          <div
            className="-mp-lay-fl"
            style={{backgroundColor:'#ffffff',borderRadius:'55px',overflowX:'hidden',overflowY:'hidden',position:'relative',width:'100%',}}
            >
            <img
              style={{margin:'0px 0px 0px 103px',width:'938px',}}
              src={plusMainMenuBackground}
              alt=""
            />
            <div name="menu-links"
              style={{color:'#111111',display:'flex',flexDirection:'column',height:'99px',justifyContent:'space-evenly',left:'894px',position:'absolute',top:'232px',}}
            >
              <button
                name="beginner"
                style={{margin:'0px 0px 0px -1px',}}
                onClick={() => goToDataWithLevel('beginner')}
              >
                <div
                  className="lang-en plus-level-menu-link"
                >
                    Beginner
                  </div>
                <div
                  className="lang-el plus-level-menu-link"
                >
                    ΑΡΧΑΡΙΟΣ
                  </div>
              </button>
              <button
                name="intermediate"
                style={{margin:'25px 0px 0px 0px'}}
                onClick={() => goToDataWithLevel('intermediate')}
              >
                <div
                  className="lang-en plus-level-menu-link"
                >
                    Intermediate
                  </div>
                <div
                  className="lang-el plus-level-menu-link"
                >
                  ΕΝΔΙΑΜΕΣΟΣ
                </div>
              </button>
              <button
                name="advanced"
                style={{margin:'25px 0px 0px 0px'}}
                onClick={() => goToDataWithLevel('advanced')}
              >
                <div
                  className="lang-en plus-level-menu-link"
                >
                    Advanced
                  </div>
                <div
                  className="lang-el plus-level-menu-link"
                  >
                    ΠΡΟΧΩΡΗΜΕΝΟΣ
                  </div>
              </button>
              <button
                name="expert"
                style={{margin:'25px 0px 0px 0px'}}
                onClick={() => goToDataWithLevel('expert')}
              >
                <div
                  className="lang-en plus-level-menu-link"
                  >
                    Expert
                  </div>
                <div
                  className="lang-el plus-level-menu-link"
                >
                  ΕΜΠΕΙΡΟΓ-<br/>
                  ΝΩΜΟΝΑΣ<br/>
                </div>
              </button>
            </div>
            <a
              className="plus-menu-link -mp-lay-fl"
              style={{height:'111px',left:'139px',top:'43px',width:'67px',}}
              href="/#/bilex-plus/about">
              <div
                className="plus-menu-link-text">About</div>
            </a>
            <a
              className="plus-menu-link -mp-lay-fl"
              style={{height:'102px',left:'365px',top:'374px',width:'67px',}}
              href="/#/bilex-kids/select-language">
              <div
                className="plus-menu-link-text">Children</div>
            </a>
            <a
              className="plus-menu-link -mp-lay-fl"
              style={{height:'99px',left:'727px',top:'87px',width:'67px',}}
              href="/#/bilex-plus/data">
              <div
                className="plus-menu-link-text"
                style={{fontSize:'16px'}}>Database</div>
            </a>
          </div>
        </div>
      </div>
  </div>
  );
}
