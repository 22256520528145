import aboutImage from '../images/plus-about-graphic.svg'

import { BXPlusHeader } from '../ui/BXPlusHeader';

export function BXPlusAboutPage() {

  return (
    <div name="page_contents"
      className="page-contents dark"
    >
      <div
        className="vert-flex-start">

        <div
          name="slot"
          style={{flexGrow:1,minHeight:'160px',minWidth:'100px',position:'relative',margin:'0px',}}
        >
          <BXPlusHeader/>
          <div
            style={{alignItems:'flex-start',backgroundColor:'#ffffff',borderRadius:'55px',display:'flex',flexDirection:'column',justifyContent:'space-around',overflowX:'hidden',overflowY:'hidden',position:'relative',width:'100%',}}
          >
            <h1
              className="plus-heading">
              About
            </h1>
            <div
              name="about_text"
              style={{alignItems:'flex-start',display:'flex',flexDirection:'row',fontSize:'14px',justifyContent:'space-between',margin:'0px 20px 0px 20px',overflowX:'hidden',overflowY:'hidden',position:'relative',}}
            >
              <div
                className="-mp-lay-fl"
                style={{width:'29%'}}
              >
                <p>
                  This is the website of BilexKids, a multi-function database developed for Greek and Greek/Cypriot children that learn Greek as a second language in an English speaking environment.
                </p>
                <p>
                  The database can be used for psycholinguistic experimental studies as well as for educational practitioners for educational purposes. Children may also use the database to assist their learning of Greek as a second language.
                </p>
              </div>
              <div
                className="-mp-lay-fl"
                style={{width:'37%'}}
              >
                <p>
                  BilexΚids is based on a corpus of ……… million words extracted from ……. textbooks covering a topics taught to Greek and Greek/Cypriot children in the UK, Australia and USA. Topics are organised in themes or subjects related to Greek culture, art, history, geography, literature, religion, theatre, citizenship. The database consists of 4 lexicons, one for each age group (5-8 year olds, 9 to 12 year olds and 12-16 year olds, the latter two are cumulative) and one for all grades.
                </p>
                <p>
                  The users can apply search criteria and choose filter variables, paste lists of words and extract results to csv files or do word by word search. They can also download the lexicons as excel files. nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                </p>
              </div>
              <div
                name="graphic"
                style={{height:'333px',position:'relative',width:'267.094px',}}
              >
                <img
                  style={{bottom:'0px',height:'277.019px',position:'absolute',right:'0px',width:'263px',}}
                  src={aboutImage}
                  alt=""
                />
                <div
                  className="-mp-lay-fl"
                  style={{backgroundColor:'#000000',bottom:'0px',height:'5px',left:'-749px',minWidth:'160px',position:'absolute',width:'861px',}}
                >
                </div>
              </div>
            </div>
            <div
              name="people_links"
              style={{alignItems:'flex-start',display:'flex',flexDirection:'row',flexWrap:'wrap',fontSize:'14px',justifyContent:'center',margin:'26px 0px 0px -1px',overflowX:'hidden',overflowY:'hidden',position:'relative',width:'100%',}}
            >
              <div
                className="people-link -mp-lay-fl"
                style={{width:'368px',}}
              >
                <div name="name"
                  className="people-link-name"
                >
                    Aris Terzopoulos
                </div>
                <a name="link"
                  className="people-link-link"
                  href="https://pureportal.coventry.ac.uk/en/persons/aris-terzopoulos"
                >
                  https://pureportal.coventry.ac.uk/en/persons/aris-terzopoulos
                </a>
              </div>
              <div
                className="people-link -mp-lay-fl" style={{width:'367px',}}
              >
                <div name="name"
                  className="people-link-name"
                >
                    Dr. Georgia Niolaki
                </div>
                <a name="link"
                  className="people-link-link"
                  href="https://pureportal.coventry.ac.uk/en/persons/georgia-niolaki"
                >
                  https://pureportal.coventry.ac.uk/en/persons/georgia-niolaki
                </a>
              </div>
              <div
                className="people-link -mp-lay-fl" style={{width:'364px',}}
              >
                <div name="name"
                  className="people-link-name"
                >
                    Dr. Lynne Duncan
                </div>
                <a name="link"
                  className="people-link-link"
                  href="https://www.dundee.ac.uk/psychology/staff/profile/lynne-duncan.php"
                >
                  https://www.dundee.ac.uk/psychology/staff/profile/lynne-duncan.php
                </a>
              </div>
              <div
                className="people-link -mp-lay-fl" style={{width:'184px',}}
              >
                <div name="name"
                  className="people-link-name"
                >
                    Mark Wilson<br/>
                </div>
                <a name="link"
                  className="people-link-link"
                  href="https://www.manypage.com">
                    majwilson@gmail.com<br/>
                </a>
              </div>
              <div
                className="people-link -mp-lay-fl" style={{width:'287px',}}
              >
                <div name="name"
                  className="people-link-name"
                >
                  Jackie Masterson
                </div>
                <a name="link"
                  className="people-link-link"
                  href="http://iris.ucl.ac.uk/iris/browse/profile?upi=JMAST91">
                    http://iris.ucl.ac.uk/iris/browse/profile?upi=JMAST91 <br/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
