
export function DBGX() {
    console.log.apply( console, makeLogArgs( "DBGX", arguments ) );
}
export function DBG() {
    console.log.apply( console, makeLogArgs( "DBG", arguments ) );
}
export function DBG_STACK() {
    console.log.apply( console, makeLogArgs( "DBG", arguments ) );
    console.trace();
}
export function INFX() {
    console.log.apply( console, makeLogArgs( "INFX", arguments ) );
}
export function INFO() {
    console.info.apply( console, makeLogArgs( "INFO", arguments ) );
}
export function WARN() {
    console.warn.apply( console, makeLogArgs( "WARN", arguments ) );
}
export function ERR() {
    console.error.apply( console, makeLogArgs( "ERR", arguments ) );
}
export function CRIT() {
    console.error.apply( console, makeLogArgs( "CRIT", arguments ) );
}
export function EXC() {
    console.error.apply( console, makeLogArgs( "EXCEPTION", arguments ) );
}

export function ASSERT( test, msg ) {
  /* kind-of makes sense here */
  if( !test ) {
      throw new Error( "ASSERT FAILED: " + msg );
  }
}

function makeLogArgs( lvl_str, iargs) {
  // 200507 - it really is better to just let console deal with the display of args
  var args = Array.prototype.slice.call( iargs );
  args.unshift( level_strs_to_strs[ lvl_str ] );
  return args;
}

var level_strs_to_strs = {
  "DBGX": "DBGX - ",
  "DBG":  "DBG  - ",
  "INFX": "INFX - ",
  "INFO": "INFO - ",
  "WARN": "WARN - ",
  "ERR":  "ERR  - ",
  "CRIT": "CRIT - "
};
