import React from "react";
import PropTypes from 'prop-types';


export const HXTableHead = (props) => {

  return (
    <div name="head" className="table-head -mp-lay-fl">
      {
        props.columnNames.map( (columnName, idx) => { 
          return <HXTableHeadCell 
            name={columnName}
            title={props.columnInfos[columnName].title}
            index={idx}
            key={columnName}
          />
        })
      }
    </div>
  )
}

HXTableHead.propTypes = {
  columnInfos: PropTypes.object,
  columnNames: PropTypes.arrayOf(PropTypes.string),
}


export const HXTableHeadCell = (props) => {
  return (
    <div name={ props.name } className={ props.name + (props.index % 2 ? ' col_odd' : '') }>
      { props.title }
    </div>
  )
}
HXTableHeadCell.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  index: PropTypes.number,
};


