import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";
import * as constants from '../constants';

import { BXKidsFooter } from '../ui/BXKidsFooter';
import { BXKidsHeader } from '../ui/BXKidsHeader';

export function BXKidsMenuPage() {
  const selectedLanguage = useSelector(state => state.selectedLanguage);

  let className = 'page-contents -mp-lay-fl vert-space-between ';
  className += (selectedLanguage === 'EL') ? 'EL-ACTIVE' : 'EN-ACTIVE';

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goToListWithLevel = (selectedLevel) => {
    const dataFileName = constants.LEVEL_TO_DATAFILE_NAME[selectedLevel];
    dispatch({type: 'dataFileName', payload: dataFileName});
    /* LINK */
    navigate('/bilex-kids/listing');
  }

  return (
    <div
      name="page_contents"
      className={className}
    >

      <div
        name="slot"
        style={{flexGrow:1,minHeight:'160px',minWidth:'100px',width:'100%',}}
      >
        <BXKidsHeader/>

        <div name="hero"
          style={{height:'248px',}}
        >
          <div
            style={{alignContent:'flex-end',alignItems:'center',display:'flex',flexDirection:'column',height:'205px',justifyContent:'flex-end',}}>
            <div
              name="translate"
              style={{alignContent:'flex-end',alignItems:'center',display:'flex',flexDirection:'column',justifyContent:'flex-end',}}
            >
              <div
                className="-mp-lay-fl"
                style={{alignItems:'center',backgroundColor:'#ffffff',borderRadius:'100px',display:'flex',flexDirection:'row',height:'100px',justifyContent:'center',margin:'0px 5px 30px 5px',minWidth:'100px',width:'100px',}}>
                <div
                  style={{color:'#1f0993',fontSize:'33px',lineHeight:'28px',textAlign:'center',textTransform:'uppercase',width:'100%',}}>
                    <span style={{fontSize:'24px',}}>Hello!</span><br/>
                </div>
              </div>
              <div
                className="lang-en"
                style={{color:'#1f0993',fontSize:'33px',margin:'0px 20px 0px 20px',textAlign:'center',textTransform:'uppercase',}}>
                  TRANSLATE<br/>
              </div>
              <div
                className="lang-el"
                style={{color:'#1f0993',fontSize:'28px',margin:'0px 20px 0px 20px',textAlign:'center',textTransform:'uppercase',}}>
                  ΜΕΤΑΦΡΑΖΩ
              </div>
            </div>
          </div>
        </div>
        <div name="menu-links"
          style={{display:'flex',flexDirection:'row',justifyContent:'space-evenly',width:'100%',}}
        >
          <button
            name="beginner"
            style={{margin:'0px 0px 0px -1px',}}
            onClick={() => goToListWithLevel('beginner')}
          >
            <div
              className="lang-en kids-menu-link"
            >
              Beginner
            </div>
            <div
              className="lang-el kids-menu-link"
            >
              ΑΡΧΑΡΙΟΣ
            </div>
          </button>
          <button
            name="intermediate"
            onClick={() => goToListWithLevel('intermediate')}
          >
            <div
              className="lang-en kids-menu-link"
            >
              Intermediate
            </div>
            <div
              className="lang-el kids-menu-link"
            >
              ΕΝΔΙΑΜΕΣΟΣ
            </div>
          </button>
          <button
            name="advanced"
            onClick={() => goToListWithLevel('advanced')}
          >
            <div
              className="lang-en kids-menu-link"
            >
              Advanced
            </div>
            <div
              className="lang-el kids-menu-link"
            >
              ΠΡΟΧΩΡΗΜΕΝΟΣ
            </div>
          </button>
          <button
            name="expert"
            onClick={() => goToListWithLevel('expert')}
          >
            <div
              className="lang-en kids-menu-link"
            >
              Expert
            </div>
            <div
              className="lang-el kids-menu-link"
            >
            ΕΜΠΕΙΡΟΓ-<br/>
            ΝΩΜΟΝΑΣ<br/>
          </div>
        </button>
      </div>
    </div>
    <BXKidsFooter />
  </div>
  );
}
