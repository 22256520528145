import * as LOG from './LOG';
import * as Util from './Util';
import * as Plane from './Plane';

const isDef = Util.isDef;
const isNull = Util.isNull;
var splitWords = Util.string.splitWords;

// dimension we use when we have an elem that's giving 0 back
var m_dflt_elem_zero_dim = 15;

const Browser = {};
/* dom elements */
export const elem = ( function() {

    var node_types = {
        ELEMENT: 1,
        ATTRIBUTE: 2,
        TEXT: 3,
        CDATA_SECTION: 4,
        ENTITY_REFERENCE: 5,
        ENTITY: 6,
        PROCESSING_INSTRUCTION: 7,
        COMMENT: 8,
        DOCUMENT: 9,
        DOCUMENT_TYPE: 10,
        DOCUMENT_FRAGMENT: 11,
        NOTATION: 12
    };

    var isElemType = function( elem, name ) {
        return elem.nodeType === node_types[ name ];
    };

    var isTextNode = function( elem ) {
        return elem.nodeType === node_types[ "TEXT" ];
    };

    var tagName = function( elem ) {
        return elem.tagName.toLowerCase();
    };

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    var readAttr = function( elem, name, fallback ) {
        if( elem.hasAttribute && elem.hasAttribute( name ) ) {
            return elem.getAttribute( name );
        }
        if( isDef( fallback ) ) {
            return fallback;
        }
        throw new Error( "missing element attribute - '" + name + "' - " + elem.toString() );
    };

    var writeAttr = function( elem, name, val ) {
        elem.setAttribute( name, val );
    };

    var writeAttrs = function( elem, attrs ) {
        Object.keys( attrs ).forEach( function( name ) {
            Browser.elem.writeAttr( elem, name, attrs[ name ] );
        } );
    };

    var clearAttr = function( elem, name ) {
        elem.removeAttribute( name );
    };

    var allAttrs = function( elem ) {
        var all_attrs = {};
        for( var attr, i = 0, attrs = elem.attributes, l = attrs.length; i < l; i++ ) {
            attr = attrs.item( i );
            all_attrs[ attr.nodeName ] = attr.nodeValue;
        }
        return all_attrs;
    };

    // ---------------------------------------------------------------------------------------------
    // enclosing elems
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // these won't work on text elements - use textEncElem() first if necessary
    var encElem = function( elem ) {
        switch( tagName( elem ) ) {
            case "body":
            case "html":
                        // if body belongs to an iframe then we want to
                        //    keep going outwards
                var wind = elem.ownerDocument.defaultView;
                return ( wind && wind.frameElement ) || null;
            default: return elem.parentNode;
        }
    };

    var encElems = function( ielem ) {
        /* only returns out to <body> */
        var encs = [], elem = ielem;
        while( ( elem = encElem( elem ) ) ) {
            encs.push( elem );
        }
        return encs;
    };

    var outElems = function( ielem ) {
        /* enc elems plus original elem */
        var outs = [ ielem ], elem = ielem;
        while( ( elem = encElem( elem ) ) ) {
            outs.push( elem );
        }
        return outs;
    };

    var textEncElem = function( elem ) {
        /* special func for text nodes only */
        if( !isTextNode( elem ) ) {
            throw new Error( "textEncElem() invalid node type - " + elem.nodeType );
        }
        return elem.parentNode;
    };

    var encElemIfText = function( node ) {
        /* return the node's container if its a text node else the node itself */
        return isTextNode( node ) ? textEncElem( node ) : node;
    };


    // ---------------------------------------------------------------------------------------------
    // sub / child elems

    /* the original findSubElemByPath() is far and away the best, but it can fail to
    backtrack correctly if there are 2 items with the same name...
    170704 - investigated further and made a notable improvement which makes
        it less likely for searches to be exhausted. */
    function findSubElemByPath( elem, path ) {
        /* probably not strictly a Browser.elem function, but used by both Pane and Glazier
            NB loose paths
            NB returns null if elem at path not found
            NB this is a key part of how MP works  */
        var outer_elem = elem;
        var found_elem;

        if( path[ 0 ] === "~" ) {
            path = path.slice( 1 );           // remove leading "~"
        }
        var pnames = path.split( "~" );
        var opens = [];

        for( var i = 0; i < pnames.length; i++ ) {
            var pname = pnames[ i ];
            // Util.list.extend( opens, childElems( outer_elem ) );
            opens = [...opens, ...childElems( outer_elem )];

            found_elem = null;
            while( opens.length ) {
                var test_elem = opens.shift();
                var test_name = Browser.elem.readAttr( test_elem, "name", null );
                if( test_name === pname ) {
                    if( Browser.elem.tagName( test_elem ) === "iframe" ) {
                                // we want iframe itself if its last part of chain, else
                                //   we continue search at the iframe's content
                        if( i === pnames.length - 1 ) {
                            found_elem = test_elem;
                        } else {
                            found_elem = Browser.iframe.getDocument( test_elem ).body;
                                // if name of body appears in path we want to bump past it
                            var body_name = Browser.elem.readAttr( found_elem, "name", null );
                            if( body_name === pnames[ i + 1 ] ) {
                                i += 1;
                            }
                        }
                    } else {
                        found_elem = test_elem;
                    }
                    break;
                }

                // Util.list.extend( opens, childElems( test_elem ) );
                opens = [...opens, ...childElems( test_elem )];
            }
            if( !found_elem ) {
                break;
            }
            outer_elem = found_elem;
            opens = [];
        }

        return found_elem;
    }


    function findSubElemB(ielem, testFunc ) {
        /* breadth-first search for elem matching testFunc
            NB tests ielem and all sub elems - if ielem matches testFunc, it will
                be returned */
        var opens = [], closeds = [];
        opens.push( ielem );

        while( opens.length ) {
            var elem = opens.shift();
            if( testFunc( elem ) ) {
                return elem;
            }
            var childs = childElems( elem );
            // Util.list.extend( opens, childs );
            opens = [...opens, ...childs];
            closeds.push( elem );
        }
        return null;
    }

    function findSubElemD( ielem, testFunc ) {
        /* depth-first search for elem matching testFunc
            NB tests ielem and all sub elems - if ielem matches testFunc, it will
                be returned */
        var opens = [], closeds = [];
        opens.push( ielem );

        while( opens.length ) {
            var elem = opens.shift();
            if( testFunc( elem ) ) {
                return elem;
            }
            var childs = childElems( elem );
            // Util.list.pretend( opens, childs );
            opens = [...childs, ...opens]
            closeds.push( elem );
        }
        return null;
    }

    function childElems( elem ) {
        /* immediate element nodes inside elem
            NB this DOES NOT return text nodes - because most of the layout
                code so far ignores them. Use allContentNodes() if text nodes
                are required. */
        if( elem.tagName && Browser.elem.tagName( elem ) === "iframe" ) {
            try {
                var iframe_doc = Browser.iframe.getDocument( elem );
                if( !iframe_doc || !iframe_doc.body ) {
                    return [];      // this sometimes happens if iframe empty
                }
                return __childElems( iframe_doc.body );
            } catch( e ) {
                if( e.toString().startsWith( "SecurityError" ) ) {
                    /* typically we get this if we've got an iframe displaying a different
                            page - its safe to ignore:
                            SecurityError: Failed to read the 'contentDocument' property from
                        'HTMLIFrameElement': Blocked a frame with origin
                        "http://localhost:9090"
                        from accessing a cross-origin frame. */
                    // pass
                } else {
                    console.warn( "childElems() failed", e.toString() );
                }
                return [];
            }
        } else {
            return __childElems( elem );
        }
    }


    function __childElems( elem ) {
        /* see childElems() */
        var childs = [];
        var node = elem.firstChild;
        while( node ) {
            if( _isContentElem( node ) ) {
                childs.push( node );
            }
            node = node.nextSibling;
        }
        return childs;
    }
    function _isContentElem( elem ) {
        /* internal use only */
        if( elem.nodeType !== 1 ) {
            return false;
        }
        var tag = elem.tagName.toLowerCase();
        return tag !== "style" && tag !== "script";
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    var getLocEnc = function( elem ) {
        return new Plane.Loc( elem.offsetLeft, elem.offsetTop );
    };

    var getLocPage = function( elem ) {
        var bnds = Browser.elem.getBoundsPage( elem );
        var left = bnds.getLeft(), top = bnds.getTop();

        if( !left && !top ) {
            if( Browser.elem.tagName( elem ) !== "iframe" ) {
                    /* for some reason iframe's seem to give us 0 size so end up
                            producing lots of warnings if they're really at (0,0) */
                if( !isRealVisible( elem ) ) {
                    var ename = readAttr( elem, "name", "no-name" );
                    LOG.WARN( "getLocPage() - element '" + ename + "' is not visible!");
                }
            }
        }
        return new Plane.Loc( left, top );
    };

    function getBounds( elem ) {
        return Browser.elem.getBoundsPage( elem ).normalize();
    }

    /*  the editors are much nicer if we can always get a bounds for the pane so if
        getClientBounds() returns empty (which it sometimes does) we make our own
        efforts to accumulate sub-elem bounds */
    function getBoundsPage( elem ) {
        var bnds = getBoundsPagePreScroll( elem );
        var wind = Browser.elem.getWindow( elem );
        var scroll = Browser.wind_port.getScroll( wind );
        bnds = bnds.offset( scroll );
        return bnds;
    }

    function getBoundsPagePreScroll( elem ) {
        /* NB the returned bounds is relative is the page (document) containing
            the element */
        var cl_bnds = Browser.elem.getClientBounds( elem );

        if( !cl_bnds.isEmpty() ) {
                     // padding / borders can sometimes make it seem non-empty
            var p = elem_style.readRaw( elem, [ "padding", "border_width" ] );
            var pads = Browser.elem.style.strToBounds( p.padding );
            var bords = Browser.elem.style.strToBounds( p.border_width );
            var test = new Plane.Bounds( cl_bnds.getWidth() - pads.sumHorz() - bords.sumHorz(),
                                         cl_bnds.getHeight() - pads.sumVert() - bords.sumVert() );
            if( !test.isEmpty() ) {
                return cl_bnds;
            }
        }
                    // accumulate bounds of child-elems. NB the flaw with this method
                    //   appears when some elements lie outside the bounds of the
                    //   container but we'll just have to live with that for now
        var child_elems = childElems( elem );
        if( child_elems.length ) {
                    // cl_bnds is likely to have valid leftTop()
            var acc_bnds = cl_bnds.copy();
            child_elems.forEach( function( el ) {
                acc_bnds = acc_bnds.union( Browser.elem.getBoundsPage( el ) );
            } );

            if( !acc_bnds.isEmpty() ) {
                return acc_bnds;
            }
        }
                                    // return minimally-sized bounds - hopefully loc is ok
        return new Plane.Bounds( m_dflt_elem_zero_dim ).offset( cl_bnds.getLeftTop() );
    }


    function getClientBounds( elem ) {
        /* this done as a separate method so we can re-plug for IE if nessary */
        var brect = elem.getBoundingClientRect();
        if( brect.width === 0 && brect.height === 0 ) {
            var orig_disp = elem_style.readRaw1( elem, "display" );
            var is_local = elem.style[ 'display' ] !== '';
            if( orig_disp === "none" ) {
                    // display temporarily so we can get the bounds
                    // NB hackish call to setProperty() instead of elem_style.writeRaw()
                    //    so we can use "important"
                elem.style.setProperty( "display", "block", "important" );
                brect = elem.getBoundingClientRect();
                if( !is_local ) {
                    orig_disp = null;   // removes property
                }
                elem_style.writeRaw( elem, "display", orig_disp );
            }
        }
        return new Plane.Bounds( brect.top, brect.left + brect.width, brect.top + brect.height, brect.left );
    }

    function getScroll( elem ) {
        return new Plane.Loc( elem.scrollLeft, elem.scrollTop );
    }
    function setScroll( elem, scroll ) {
        elem.scrollLeft = scroll.getHorz();
        elem.scrollTop = scroll.getVert();
    }
    function getScrollMax( elem ) {
        var sc_wid = elem.scrollWidth - elem.offsetWidth;
        var sc_hgt = elem.scrollHeight - elem.offsetHeight;
        return new Plane.Loc( sc_wid > 0 ? sc_wid : 0, sc_hgt > 0 ? sc_hgt : 0 );
    }
    function getScrollHeight( elem ) {
        return elem.scrollHeight;
    }

    function isRealVisible( elem ) {
        /* NB this still returns true if the pane has visibility:hidden */
        var brect = elem.getBoundingClientRect();
        return !( brect.width === 0 && brect.height === 0 &&
                                            brect.left === 0 && brect.top === 0 );
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    function getWindow( elem ) {
        return elem.ownerDocument.defaultView;
    }
    function getDocument( elem ) {
        return getWindow( elem ).document;
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    return {
        node_types: node_types,
        isElemType: isElemType,
        isTextNode: isTextNode,
        tagName: tagName,
        // getById: getById,
        // getByTag: getByTag,
        // makeText: makeText,
        // makeElem: makeElem,
        // makeFrag: makeFrag,
        // append: append,
        // remove: remove,
        // insert: insert,
        readAttr: readAttr,
        writeAttr: writeAttr,
        writeAttrs: writeAttrs,
        clearAttr: clearAttr,
        allAttrs: allAttrs,
        encElem: encElem,
        encElems: encElems,
        outElems: outElems,
        textEncElem: textEncElem,
        encElemIfText: encElemIfText,
        findSubElemByPath: findSubElemByPath,
        findSubElemB: findSubElemB,
        findSubElemD: findSubElemD,
        // getNodeIndex: getNodeIndex,
        // getNodeByIndex: getNodeByIndex,
        childElems: childElems,
        // allContentNodes: allContentNodes,
        // textChildNodes: textChildNodes,
        // allChildNodes: allChildNodes,
        // hasText: hasText,
        // subElemsB: subElemsB,
        // subNodesB: subNodesB,
        // subElemsD: subElemsD,
        // subNodesD: subNodesD,
        // prevElem: prevElem,
        // nextElem: nextElem,
        // nextElemOrText: nextElemOrText,
        // firstChildNode: firstChildNode,
        // adjustPageCoords: adjustPageCoords,
        // adjustWinCoords: adjustWinCoords,
        getLocEnc: getLocEnc,
        getLocPage: getLocPage,
        getBounds: getBounds,
        getBoundsPage: getBoundsPage,
        getClientBounds: getClientBounds,
        getScroll: getScroll,
        setScroll: setScroll,
        getScrollMax: getScrollMax,
        getScrollHeight: getScrollHeight,
        isRealVisible: isRealVisible,
        // // makeUniqueElemName: makeUniqueElemName,
        // innerHtml: innerHtml,
        // innerText: innerText,
        // outerHtml: outerHtml,
        // setHtml: setHtml,
        // addHtml: addHtml,
        // replaceHtml: replaceHtml,
        // makeTagText: makeTagText,
        // getNodeText: getNodeText,
        // setNodeText: setNodeText,
        getWindow: getWindow,
        getDocument: getDocument,
    };
} )();

// -------------------------------------------------------------------------------------------------
/* element styles - elem.style
    routines to handle the "style" attribute of an element */
export const elem_style = ( function() {
    // ---------------------------------------------------------------------------------------------
    // additional "luxuries" - we could have Util.css but this probly makes as much sense

    /* assumes pixels only - deal with other units later */
    function strToBounds( str ) {
        if( !str ) {
            return new Plane.Bounds( 0 );
        }
        var vals = str.split( " " ).map( function( i ){ return parseInt( i, 10 ); } );
        if( isNaN( vals[ 0 ] ) ) {
            return new Plane.Bounds( 0 );
        }
        if( vals.length === 1 ) {
            return new Plane.Bounds( vals[ 0 ] );
        } else if( vals.length === 2 ) {
            return new Plane.Bounds( vals[ 0 ], vals[ 1 ] );
        } else {
            return new Plane.Bounds( vals[ 0 ], vals[ 1 ], vals [ 2 ], vals[ 3 ] );
        }
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    function boundsToStr( bnds, units='px' ) {
        var strs = [ bnds._.top, bnds._.right, bnds._.bot, bnds._.left ];
        return strs.join( units + " " ) + units;
    }


    // ---------------------------------------------------------------------------------------------
    // at some point it would be nice to provide type coercion for css values
    //    but for now, code has to use readRaw() / writeRaw() with string vals
    // read()
    // write()


    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    function readRaw1( elem, name ) {
        return elem_style.readRaw( elem, [ name ] )[ name ];
    }

    /* read a series of items as raw strings */
    function readRaw( elem, names ) {
        // var acting_style = document.defaultView.getComputedStyle( elem, null );
        // var acting_style = window.getComputedStyle( elem, null );
        var acting_style = getComputedStyle( elem, null );
        if( !acting_style ) {
            return null;
        }
        var vals = {};
        names.forEach( function( name ) {
            vals[ name ] = elem_style.readProp( acting_style, name );
        } );
        return vals;
    }

    /* write a series of raw strings as items */
    function writeRaw( elem, arg2, arg3 ) {
        var props = {};
        if( isDef( arg3 ) ) {
            props[ arg2 ] = arg3;
        } else {
            props = arg2;
        }
        Object.keys( props ).forEach( function( name ) {
            elem_style.writeProp( elem.style, name, props[ name ] );
        } );
    }

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    function readAllProps( style ) {
        /* read all props into a js object */
        var props = {};
        for( var i = style.length; i--; ) {
            var pname = style[ i ];
            props[ pname ] = elem_style.readProp( style, pname );
        }
        return props;
    }

    function writeAllProps( style, props ) {
        Object.keys( props ).forEach( function( pname ) {
            elem_style.writeProp( style, pname, props[ pname ] );
        } );
    }

    // ---------------------------------------------------------------------------------------------
    // private(ish)

    /* return the raw css string for the item with name
    NB this returns the acting style applied to the element. It is not
        possible to determine whether this comes from an local declaration,
        or a css style name */
    function readProp( style, name ) {
        var fix_names = elem_style.fixPropName( name );
        var vals = fix_names.map( function( fix_name ) {
            var prop = style[ fix_name ];
            if( !prop ) {
                return null;
            }
            prop = elem_style.fixPropValue( prop );
                /* we need to get rid of spaces here so that splitWords() in writeProp()
                    works as intended. This problem was originally shown when Chrome
                    started returning rgb colors (with spaces) instead of hex strings */
            return prop.replace( /\s+/g, "" );
        } );
        return vals[ 0 ] === null ? null : vals.join( " " );
    }

    function writeProp( style, name, prop ) {
        /* write the raw css string for the item with name
            NB if prop is null, this clears the style from the elem*/
        function writeP( istyle, iname, iprop ) {
            if( isNull( iprop ) ) {
                istyle.removeProperty( iname );
            } else {
                /* 180120 - if we're editting local style props that have been rendered
                    in a style table for the sake of media rules, !important is set
                    which we'll preserve here even tho it doesn't appear to make a lot
                    of difference just now */
                var prio = istyle.getPropertyPriority( name ) === 'important';
                istyle.setProperty( iname, iprop, prio ? "important" : "" );
            }
        }
        var fix_names = elem_style.fixPropName( name );
        if( fix_names.length === 1 ) {
            writeP( style, fix_names[ 0 ], prop );
        } else {
            var fix_props = isNull( prop ) ? [ null ] : splitWords( prop );
            switch( fix_props.length ) {
                case 0:
                    fix_props = [ "" ];
                    /* falls through */
                case 1:
                    fix_names.forEach( function( fix_name ) {
                        writeP( style, fix_name, fix_props[ 0 ] );
                    } );
                break;
                case 4:     // FIXME - zip()? - somewhat of a weak hack but ok for now
                    [ 0, 1, 2, 3 ].forEach( function( idx ) {
                        writeP( style, fix_names[ idx ], fix_props[ idx ] );
                    } );
                break;
                default:
                    throw new Error( "invalid string for property " + name + " - '" + prop + '"' );
                // break;
            }
        }
    }

    /* considered addProp() as well but that's pretty much the same as writeProp() */
    function removeProp( style, name ) {
        var fix_names = elem_style.fixPropName( name );
        fix_names.forEach( function( fix_name ) {
            style.removeProperty( fix_name );
        } );
    }


    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    /* map "cody" prop names to names used in browser.
        NB since we need to deal with "_" sep  to "-" sep, it makes sense
            to deal with "box" items here also
        NB if the prop name has no underscores, and its not a box prop then
            it doesn't need to be here */

    var uscore_rgx = new RegExp( "_", "g" );

    function fixPropName( name ) {
        /* NB returns an array of names */
        return prop_names[ name ] || [ name.replace( uscore_rgx, "-" ) ];
    }
    var prop_names_init = {
        /* "box" items */
        border_color: "border-top-color border-right-color border-bottom-color border-left-color",
        border_style: "border-top-style border-right-style border-bottom-style border-left-style",
        border_width: "border-top-width border-right-width border-bottom-width border-left-width",
        border_radius: "border-top-left-radius border-top-right-radius border-bottom-right-radius border-bottom-left-radius",

        margin: "margin-top margin-right margin-bottom margin-left",
        padding: "padding-top padding-right padding-bottom padding-left",

        /* these are a slight hack but they work as long as we put the associated
            property values in the right form - which we do for the spves */
        scale: "transform",    // eg transform:scale(0.7,0.7)
        rotate: "transform",   // eg transform:rotate(33deg)

        nocomma: ""
    };

    /* create arrays of "real" prop names for every "cody" prop name that needs it */
    var prop_names = {};
    Object.keys( prop_names_init ).forEach( function( name ) {
        prop_names[ name ] = splitWords( prop_names_init[ name ] );
    } );

    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    // FIXME - this may not be a good idea
    // var dash_rgx = new RegExp( "-" );
    function fixPropValue( val ) {
        /* I think it may make most sense for our string values to have underscores
            instead of dashes */
        return val;
        // return Util.isString( val ) ? val.replace( dash_rgx, "_" ) : val;
    }


    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    return {
        strToBounds: strToBounds,
        boundsToStr: boundsToStr,
        readRaw1: readRaw1,
        readRaw: readRaw,
        writeRaw: writeRaw,
        readAllProps: readAllProps,
        writeAllProps: writeAllProps,

        // needed for patching
        readProp: readProp,
        writeProp: writeProp,
        removeProp: removeProp,
        fixPropName: fixPropName,
        fixPropValue: fixPropValue
    };
} )();

// -------------------------------------------------------------------------------------------------
/* element style names - elem.style.names
    routines to handle the "className" attribute of an element

    ||===== =||= \\   // ||\\   //|| ||=====
    ||       ||   \\ //  || \\ // || ||
    ||===    ||     X    ||   V   || ||===
    ||       ||   // \\  ||       || ||
    ||      =||= //   \\ ||       || ||=====

    use elem.classList instead of elem.className for better performance - see
        https://youtu.be/hZJacl2VkKo
*/
var elem_style_names = {
    has: function( elem, name ) {
        try {       // svg's className has no search() method
            if( elem.className.search( name ) < 0 ) {
                return false;
            }
        } catch( e )
             { return false; }

                    // \b no good for style names which start with "-"
                    //    so here we replace "-" with "_" which is a bit crap
                    //    but works
        var dash_exp = new RegExp( "-", "g" );
        var rgx = new RegExp( "\\b" + name.replace( dash_exp, "_" ) + "\\b" );
        return elem.className.replace( dash_exp, "_" ).search( rgx ) >= 0;
    },

    contains: function( elem, what ) {
        /* string match */
        if( !elem.className.length ) {
            return false;
        }
        return Util.string.contains( elem.className, what );
    },

    add: function( elem, name ) {
        /* put new name at start - easier to see in firebug etc */
        if( !elem_style_names.has( elem, name ) ) {
            elem.className = elem.className ? name + " " + elem.className : name;
        }
        return elem.className;
    },
    rmv: function( elem, name ) {
        if( elem_style_names.has( elem, name ) && name.length ) {
            var names = elem.className.split( /\s+/ );
            var idx = names.indexOf( name );
            names.splice( idx, 1 );
            elem.className = names.join( " " );
        }
        return elem.className;
    },
    clear: function( elem ) {
        elem.className = "";
    },
    all: function( elem ) {
        return elem.className.length ? elem.className.split( /\s+/ ) : [];
    },

    cond: function( elem, cond, name ) {
        if( cond ) {
            elem_style_names.add( elem, name );
        } else {
            elem_style_names.rmv( elem, name );
        }
    },

    either: function( elem, cond, first, second ) {
        if( cond ) {
            elem_style_names.add( elem, first );
            elem_style_names.rmv( elem, second );
        } else {
            elem_style_names.rmv( elem, first );
            elem_style_names.add( elem, second );
        }
    },

    choice: function( elem, isname, snames ) {
        /* snames can be a list of possible styles only one of which - isname -
            we want applied to the elemen. Or it can be a mapping of arbitrary
            strings to style names, and isname is a key into that mapping */
        if( Util.isList( snames ) ) {
            snames.forEach( function( ik ) {
                elem_style_names.rmv( elem, ik );
            } );
            elem_style_names.add( elem, isname );
        } else {
            Object.keys( snames ).forEach( function( ik ) {
                elem_style_names.rmv( elem, ik );
            } );
            var sname = snames[ isname ];
            if( sname ) {
                elem_style_names.add( elem, sname );
            }
        }
    },

    O:0
};

// -------------------------------------------------------------------------------------------------
/* the viewport */
export const wind_port = ( function() {

    function getWinBounds( wind ) {
        /* the size of the window's content area */
        wind = wind || window;
        var wid, hgt;
        if( typeof( window.innerWidth ) === "number" ) {
            wid = window.innerWidth;
            hgt = window.innerHeight;
        } else if( wind.document.body && (
                        wind.document.body.clientWidth || wind.document.body.clientHeight ) ) {

            wid = wind.document.body.clientWidth;
            hgt = wind.document.body.clientHeight;
        }
        return new Plane.Bounds( wid, hgt );
    }

    function getPageBounds( wind ) {
       /* the size of the page within the browser window - may
           exceed port bounds if scrolling */
        wind = wind || window;
        var wid, hgt;
        if( isDef( wind.innerHeight ) && isDef( wind.scrollMaxY ) ) {
                    // FF and pals
            wid = wind.document.body.scrollWidth;
            hgt = wind.innerHeight + wind.scrollMaxY;
        } else if( wind.document.body.scrollHeight > wind.document.body.offsetHeight ) {
                    // all but Explorer Mac
            wid = wind.document.body.scrollWidth;
            hgt = wind.document.body.scrollHeight;
        } else {
                    // Explorer Mac, Mozilla and Safari
            wid = wind.document.body.offsetWidth;
            hgt = wind.document.body.offsetHeight;
        }
        return new Plane.Bounds( wid, hgt );
    }


    var getScroll = function( wind ) {
        /* the amount that the contents of the window are scrolled */
        wind = wind || window;
        return new Plane.Loc( wind.pageXOffset, wind.pageYOffset );
    };
    var setScroll = function( wind, scroll ) {
        /* the amount that the contents of the window are scrolled */
        wind = wind || window;
        wind.scrollTo( {
            left: scroll.getHorz(), top: scroll.getVert(),
            behavior: 'auto'        // or "smooth"
        } );
    };

    var getAvailScroll = function( wind ) {
        wind = wind || window;
        var doc_elem = wind.document.documentElement;
        var scroll_max_x = wind.scrollMaxX || ( doc_elem.scrollWidth - doc_elem.clientWidth );
        var scroll_max_y = wind.scrollMaxY || ( doc_elem.scrollHeight - doc_elem.clientHeight );
        return new Plane.Loc( scroll_max_x, scroll_max_y );
    };

    var getActiveElem = function( wind, prev_act ) {
        /* the currently focused element, ie will get keystroke events if user types */
        wind = wind || window;
        try {
            var act_elem = wind.document.activeElement;
        } catch( e ) {
            /* DOMException: Blocked a frame with origin "http://localhost:9090" from
            accessing a cross-origin frame. */
            return null;
        }
        if( Browser.elem.tagName( act_elem ) === "iframe" ) {
            if( prev_act && prev_act === act_elem ) {
                return act_elem;        // prevent unlimited recursion
            }
            return getActiveElem( Browser.iframe.getWindow( act_elem ), act_elem );
        } else {
            return act_elem;
        }
    };

    var getBody = function( wind ) {
        return wind.document.body;
    };

    var getParent = function( wind ) {
        /* the parent window */
        return wind.parent === wind ? null : wind.parent;
    };

    var getIFrame = function( wind ) {
        /* null for outer window */
        return wind.frameElement;
    };

    return {
        getWinBounds: getWinBounds,
        getPageBounds: getPageBounds,
        getScroll: getScroll,
        setScroll: setScroll,
        getAvailScroll: getAvailScroll,
        getActiveElem: getActiveElem,
        getBody: getBody,
        getParent: getParent,
        getIFrame: getIFrame
    };
} )();

Browser.elem = elem;
Browser.elem.style = elem_style;
Browser.elem.style.names = elem_style_names;
Browser.wind_port = wind_port;
