import React, {useState, useEffect, useRef} from "react";
import PropTypes from 'prop-types';

import { Pane } from '../util/Pane';
import { Loc } from '../util/Plane';

export const HXButton = (props) => {
  const [isPressed, setIsPressed] = useState(props.isPressed);
  const [isTracking, setIsTracking] = useState(false);
  const thisElem = useRef(null);

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const handleButtonDown = event => {
    setIsPressed(true);
    setIsTracking(true);
  }

  const useMousePosition = () => {
    const [ mousePosition, setMousePosition ] = useState(new Loc());
  
    useEffect(() => {
      const updateButtonUp = event => {
        setIsPressed(false);
        setIsTracking(false);
        if (isPressed && props.onClick) {
          props.onClick();
        }
      }
    
      const updateMousePosition = ev => {
        const newMouseLoc = new Loc(ev.clientX, ev.clientY)
        if (isTracking) {
          const thisPane = new Pane(thisElem.current);
          const isPressed = thisPane.getBoundsWin().containsLoc(newMouseLoc);
          setIsPressed(isPressed);
        }
      
        setMousePosition(newMouseLoc);
      };
      
      window.addEventListener('mousemove', updateMousePosition);
      window.addEventListener('mouseup', updateButtonUp);
  
      return () => {
        window.removeEventListener('mousemove', updateMousePosition);
        window.removeEventListener('mouseup', updateButtonUp);
      };
    });
  
    return mousePosition;
  };
  
  useMousePosition();

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  let buttonClass = '-hx-button -mp-lay-fl';
  buttonClass += ' ' + (props.typeClass || '-hx-button-action');
  if (isPressed) {
    buttonClass += ' PRESSED';
  }
  let buttonStyle = { width: 'auto' };
  if(props.width) {
    buttonStyle.width = props.width + 'px';
  } 
  if(props.extraStyle) {
    buttonStyle = {...buttonStyle, ...props.extraStyle};
  }
  return (
    <div ref={thisElem} className={buttonClass} style={buttonStyle} onMouseDown={handleButtonDown}>
      <div name="label" className="-hx-button-label">
        {props.title}
      </div>
    </div>
  );
};

HXButton.propTypes = {
  title: PropTypes.string,
  width: PropTypes.number,
  extraStyle: PropTypes.object,
  typeClass: PropTypes.string,
  onClick: PropTypes.func,
  isPressed: PropTypes.bool,
}


