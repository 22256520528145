
export const objectsToCsv = (rowObjects, columnHeads) => {
  let csv = '';
  rowObjects.forEach(rowObject => {
    columnHeads.forEach((headName, i) => {
      const item = rowObject[headName];
      let innerValue = item === null ? '' : item.toString();
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) {
        result = '"' + result + '"'
      }
      if (i > 0) {csv += ','}
      csv += result;
    })
    csv += '\n';
  })
  return csv
}
