import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux'

import { calculateCsvDataUrl } from '../data/calculateDataUrl';
import { csvToObjects } from '../data/HXCsvToObjects';

export const BXTranslationList = (props) => {
  const [allRows, setAllRows] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dataFileName = useSelector(state => state.dataFileName);
  const selectedLanguage = useSelector(state => state.selectedLanguage);
  const dataUrl = calculateCsvDataUrl(dataFileName);

  useEffect( () => {

    const loadData = async () => {
      setIsLoading(true);

      const response = await fetch(dataUrl)
      if (!response.ok) {
        throw new Error(`failed to get data from ${ dataUrl }`)
      }
      const csvText = await response.text();
      const allRowsLocal = csvToObjects(csvText);
      // console.log({allRowsLocal});
      setAllRows(allRowsLocal);
      setIsLoading(false);

    }
    loadData();
  }, [dataUrl]);

  let englishToGreek = selectedLanguage === 'EN';

  const processRowEnglishToGreek = (row, translations) => {
    if(row.TRANSLATION1) {
      translations.push({source:row.TRANSLATION1, dest: row.word});
    }
    if(row.TRANSLATION2) {
      translations.push({source:row.TRANSLATION2, dest: row.word});
    }
    if(row.TRANSLATION3) {
      translations.push({source:row.TRANSLATION3, dest: row.word});
    }
  };
  const processRowGreekToEnglish = (row, translations) => {
    if(row.TRANSLATION1) {
      translations.push({source:row.word, dest: row.TRANSLATION1});
    }
    if(row.TRANSLATION2) {
      translations.push({source:row.word, dest: row.TRANSLATION2});
    }
    if(row.TRANSLATION3) {
      translations.push({source:row.word, dest: row.TRANSLATION3});
    }
  }

  let sourceLang = '';
  let destLang = '';
  if(englishToGreek) {
    sourceLang = 'English';
    destLang = 'Greek';
  } else {
    sourceLang = 'Greek';
    destLang = 'English';
  }
  let sourceWords = [];
  let destWords = [];
  if(allRows) {
    let allTranslations = [];
    if(englishToGreek) {
      allRows.forEach(r => processRowEnglishToGreek(r, allTranslations));
    } else {
      allRows.forEach(r => processRowGreekToEnglish(r, allTranslations));
    }
    allTranslations.sort((a, b) => a.source.localeCompare(b.source))

    sourceWords = allTranslations.map(t => t.source);
    destWords = allTranslations.map(t => t.dest);
  }

  return (

    <div name="contents" className="list-contents">
      <div name="heading" className="clearfix"
        style={{margin:'1px 0px 0px 0px',width:'100%',}}>
        <div
          className="listing-sub-head-left"
        >
            {sourceLang}
          </div>
        <div
          className="listing-sub-head-right"
        >
            {destLang}
        </div>
      </div>
      <div name="results" className="list-contents-results">
        <div
          name="english"
          className="list-contents-results-left"
        >
          {
            isLoading ?
              `${sourceLang} words coming up...`
              :
              sourceWords.map((w, i) => <div key={i}>{w}</div>)
          }
        </div>
        <div
          name="greek"
          className="list-contents-results-right"
        >
          {
            isLoading ?
              `${destLang} words coming up...`
              :
              destWords.map((w, i) => <div key={i}>{w}</div>)
          }
        </div>
      </div>
    </div>


  )
}