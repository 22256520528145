import React, { useRef } from "react";
import { useSelector } from 'react-redux'

import { calculateExcelDataUrl } from '../data/calculateDataUrl';

import { HXButton } from './HXButton';


export const BXDownloadExcel = (props) => {

  const downloadElem = useRef(null);
  const dataFileName = useSelector(state => state.dataFileName)
  const downloadUrl = calculateExcelDataUrl(dataFileName);

  const doDownload = async () => {
    downloadElem.current.click();
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (
    <>
      <HXButton
        title="download all as excel"
        typeClass="-hx-button-success"
        width={200}
        extraStyle={{float:'right',margin:'6px 48px 0px 0px',width:'200px',}}
        onClick={doDownload}
      />

      <a
        ref={downloadElem}
        href={downloadUrl}
        download={`BilexWordFrequencies.xlsx`}
        style={{display:'none'}}
      >
        download
      </a>
    </>
  )
}

BXDownloadExcel.propTypes = {
}
