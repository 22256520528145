import React from "react";
import { useSelector, useDispatch } from 'react-redux'

import PropTypes from 'prop-types';

import { HXCheckBox } from './HXCheckBox';
import { HXPopMenu } from './HXPopMenu';
import { HXTextEntry } from './HXTextEntry';

export const HXFilterBox = (props) => {

  const selectFilterState = state => state.filters[props.filterName]
  const filterState = useSelector(selectFilterState);

  const dispatch = useDispatch();
  const onChangeIsActive = (newIsActive) => {
    dispatch({type: 'filter/isActive', payload: {filterName: props.filterName, isActive: newIsActive}});
  }
  const onChangeSelectedOption = (newOption) => {
    dispatch({type: 'filter/condition', payload: {filterName: props.filterName, condition: newOption}});
  }
  const onChangeValue = (newValue) => {
    dispatch({type: 'filter/value', payload: {filterName: props.filterName, value: newValue}});
  }

  return (
    <div name={props.filterName} className="filter_box -mp-lay-fl">
      <HXCheckBox
        label={props.title}
        isChecked={filterState.isActive}
        onChange={onChangeIsActive}
      />
      <HXPopMenu
        availableOptions={props.availableConditions}
        selectedOption={filterState.condition}
        onChange={onChangeSelectedOption}
      />
      <HXTextEntry
        enteredText={filterState.value}
        onChange={onChangeValue}
      />
    </div>
  );
}

HXFilterBox.propTypes = {
  filterName: PropTypes.string,
  title: PropTypes.string,
  availableConditions: PropTypes.arrayOf(PropTypes.shape({name: PropTypes.string, display: PropTypes.string})),
}