import React, { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux'

import { HXTableHead } from './HXTableHead';
import { HXTableBody } from './HXTableBody';
import { HXTableEmptyBody } from './HXTableEmptyBody';
import { HXTableLoadingBody } from './HXTableLoadingBody';

import { loadRowStore } from '../../data/LoadRowStore';
import { calculateCsvDataUrl } from '../../data/calculateDataUrl';

import { debounce } from '../../util/debounce';

export const HXTable = (props) => {
  const [rowStore, setRowStore] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [scrollDistance, setScrollDistance] = useState(0);
  const [sheetHeight, setSheetHeight] = useState(0);

  const dataFileName = useSelector(state => state.dataFileName)
  const dataUrl = calculateCsvDataUrl(dataFileName);

  const filters = useSelector(state => state.filters)
  const wordsList = useSelector(state => state.wordsList)

  const sheetElem = useRef(null);
  const tableElem = useRef(null);

  useEffect( () => {
    const loadData = async () => {
      setIsLoading(true);
      const newRowsStore = await loadRowStore(dataUrl, wordsList);
      setRowStore(newRowsStore);
      setIsLoading(false);
      window.setTimeout(() => {
        setSheetHeight(sheetElem.current.clientHeight);
      }, 100);  
    }
    if(sheetElem.current){
      loadData();
    }
  }, [dataUrl, wordsList]);

  useMemo( () => {
    if(rowStore) {
      rowStore.updateFilteredRows(filters);
    }
  }, [rowStore, filters])

  const handleBodyScroll = ev => {
    // console.log({scroll: ev.target.scrollTop});
    setScrollDistance(ev.target.scrollTop);
    // setSheetHeight(sheetElem.current.clientHeight);
  };
  const handleBodyScroll_db = debounce(handleBodyScroll, 1);
  
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const renderBody = () => {
    if(!rowStore || isLoading) {
      return (
        <HXTableLoadingBody 
          columnNames={props.columnNames}
        />      
      )
    }
    if(!rowStore.getFilteredRowsCount()) {
      return (
        <HXTableEmptyBody
          columnNames={props.columnNames}
        />
      )
    }
    return (
      <HXTableBody 
        columnInfos={props.columnInfos}
        columnNames={props.columnNames}
        rowStore={rowStore}
        scrollDistance={scrollDistance}
        sheetHeight={sheetHeight}
      />
    )
  }

  return (
    <div name="table" className="hx-table -mp-lay-fl">
      <HXTableHead
        columnInfos={props.columnInfos}
        columnNames={props.columnNames}
      />

    <div ref={sheetElem} 
      name="sheet" 
      className="hx-table-sheet -mp-lay-fl" 
      onScroll={handleBodyScroll_db}>
      <table ref={tableElem} style={{width:'calc(100% -12px)',height:'100%', maxHeight:'10000px'}} cellSpacing="0" name="!etjp" >
          <tbody>
            {renderBody()}
          </tbody>
      </table>
    </div>
  </div>
  ) 
}


HXTable.propTypes = {
  columnInfos: PropTypes.object,
  columnNames: PropTypes.arrayOf(PropTypes.string),
}



