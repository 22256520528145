// -------------------------------------------------------------------------------------------------
// exceptions
export class Exception extends Error {
  constructor( msg ) {
      /* an overridden toString() method does not get called from console etc
          so its less use than we'd like. This is a bit better */
      super( msg );
      this.message = this.constructor.name + ": " + msg;
  }
  // toString() {
  //     return super.toString().replace( "Error: ", this.constructor.name + ": " );
  // }
}
export class KeyError extends Exception {};

export class AttributeError extends Exception {};

export class TypeError extends Exception {};

export class NameError extends Exception {};

export class ValueError extends Exception {};

export class IndexError extends Exception {};

export class SyntaxError extends Exception {};



// -------------------------------------------------------------------------------------------------
// exception utils

export const getExceptClassName = function( eexcept ) {
  var cname = eexcept.constructor.name;
  return cname.replace( /Err(or)?/, " Error" );
};

export const getExceptMessage = function( eexcept ) {
  return eexcept.message;
};

