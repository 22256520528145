import { csvToObjects } from './HXCsvToObjects';
import { HXRowsStore } from './HXRowsStore';


export const loadRowStore = async (url, wordsList) => {

  const response = await fetch(url)
  if (!response.ok) {
    throw new Error(`failed to get data from ${ url }`)
  }
  const csvText = await response.text();
  const allRows = csvToObjects(csvText);
  let filteredRows = [];

  if(wordsList.isActive && wordsList.words.length) {
    filteredRows = allRows.filter( r => wordsList.words.includes(r.word));
  }
  if(!filteredRows.length) {
    filteredRows = allRows;
  }

  const newRowsStore = new HXRowsStore(filteredRows);

  return newRowsStore;
}

