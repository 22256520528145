import './App.css';
import './bilexkids.css';

import { HashRouter, Routes, Route } from 'react-router-dom';

import { BXDataPage } from './pages/BXDataPage';
import { BXHomePage } from './pages/BXHomePage';
import { BXKidsMenuPage } from './pages/BXKidsMenuPage';
import { BXListingPage } from './pages/BXListingPage';
import { BXPlusAboutPage } from './pages/BXPlusAboutPage';
import { BXPlusInfoPage } from './pages/BXPlusInfoPage';
import { BXPlusMenuPage } from './pages/BXPlusMenuPage';
import { BXSelectLanguagePage } from './pages/BXSelectLanguagePage';

function App() {
  return (

    <HashRouter>
      <Routes>
        <Route
          exact
          path='/'
          element={<BXHomePage/>}
        />
        <Route
          exact
          path='/bilex-kids/menu'
          element={<BXKidsMenuPage/>}
        />
        <Route
          exact
          path='/bilex-kids/select-language'
          element={<BXSelectLanguagePage/>}
        />
        <Route
          exact
          path='/bilex-kids/listing'
          element={<BXListingPage/>}
        />
        <Route
          exact
          path='/bilex-plus/menu'
          element={<BXPlusMenuPage/>}
        />
        <Route
          exact
          path='/bilex-plus/info'
          element={<BXPlusInfoPage/>}
        />
        <Route
          exact
          path='/bilex-plus/about'
          element={<BXPlusAboutPage/>}
        />
        <Route
          exact
          path='/bilex-plus/data'
          element={<BXDataPage/>}
        />
      </Routes>
    </HashRouter>
  );
}

export default App;
