import React from "react";
import { useSelector, useDispatch } from 'react-redux'

import { HXButton } from './HXButton';
import { HXCheckBox } from './HXCheckBox';


export const BXWordsListControls = (props) => {
  const wordsListIsActive = useSelector(state => state.wordsList.isActive);
  const wordsListWords = useSelector(state => state.wordsList.words);
  const dispatch = useDispatch();
  const handleChangeWordsListIsActive = (isActive) => {
    dispatch({type: 'wordsList/isActive', payload: isActive});
  }
  const handleOpenWordsListDialog = () => {
    dispatch({type: 'wordsList/words', payload: wordsListWords});
    dispatch({type: 'wordsList/dialogIsOpen', payload: true});
  }
  
  const renderWordsList = () => {
    if(!wordsListWords.length) {
      return 'no words specified';
    }
    if(wordsListWords.length === 1) {
      return wordsListWords[0];
    }
    return `${ wordsListWords[0] } and ${ wordsListWords.length - 1 } others`;
  }
  return (

    <div 
      name="word-select" 
      className="filter_box -mp-lay-fl"
      style={{width:'461px'}}>

      <HXCheckBox 
        label={'words list'}
        isChecked={wordsListIsActive}
        onChange={handleChangeWordsListIsActive}
      />

      <div name="words_summary" 
        style={{boxSizing:'border-box',fontSize:'14px',margin:'0px',padding:'5px',width:'50%',}}>  
        {renderWordsList()}
      </div>

      <HXButton 
        title="change list"
        typeClass="-hx-button-action"
        width={98}
        onClick={handleOpenWordsListDialog}
        extraStyle={{float:'right',margin:'5px',width:'98px',}}
      />

    </div>
  )
}

