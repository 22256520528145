import * as Exception from './Exception';


// -------------------------------------------------------------------------------------------------
// type-ish
export const isDef = ( obj ) => {
    /**
    args:
        obj (any): the object to test
    return: bool: true if obj is not the javascript undefined value
    */
            /* 160526 - chrome Version 50.0.2661.102 (64-bit). If obj is null,
                this browser sometimes returns false for typeof obj !== "undefined"
                (but only when the debugger is not active). Some research shows that
                comparing obj directly to undefined is the current best practice,
                and this approach fixes the problem. */
    // return typeof obj !== "undefined";
    return obj !== undefined;
};

export const isUndef = ( obj ) => {
    /**
    args:
        obj (any): the object to test
    return: bool: true if obj is the javascript undefined value
    */
            /* see 160526 notes above */
    // return typeof obj === "undefined";
    return obj === undefined;
};

export const isNull = ( obj ) => {   // NB "null" is a reserved word
    /**
    args:
        obj (any): the object to test
    return: bool: true if obj is the javascript null value
    */
    return obj === null;
};

export const isArray = ( obj ) => {
    /**
    args:
        obj (any): the object to test
    return: bool: true if obj is a javascript array
    */
    return toString.call( obj ) === "[object Array]";
};
export const isList = isArray;

export const isFunc = ( obj ) => {
    /**
    args:
        obj (any): the object to test
    return: bool: true if obj is a javascript function
    */
    return typeof obj === "function";
};

export const isString = ( obj ) => {
    /**
    args:
        obj (any): the object to test
    return: bool: true if obj is a javascript string
    */
    return toString.call( obj ) === "[object String]";
};

export const isNumber = ( obj ) => {
    /**
    args:
        obj (any): the object to test
    return: bool: true if obj is a javascript number
    */
    return toString.call( obj ) === "[object Number]" && !isNaN( obj );
};

export const isBool = ( obj ) => {
    /**
    args:
        obj (any): the object to test
    return: bool: true if obj is a javascript boolean
    */
    return typeof obj === "boolean";
};

export const isObject = ( obj ) => {
    /**
    args:
        obj (any): the object to test
    return: bool: true if obj is a javascript object;
    */
    // return obj !== null && typeof obj === "object"
    return toString.call( obj ) === "[object Object]";
};

export const isNan = ( obj ) => {
    /**
    args:
        obj (any): the object to test
    return: bool: true if obj is the javascript NaN value
    */
    return isNaN( obj );
};

export const isElem = ( obj ) => {
    /**
    args:
        obj (any): the object to test
    return: bool: true if obj is a DOM element
    */
    // return !!( obj && obj.nodeType === 1 && obj.nodeName.toLowerCase() !== "style" );
    return obj && isDef( obj.nodeType ) && obj.nodeType === 1;
};

export const isTruthy = function( obj ) {
    return obj && ( Object.keys( obj ).length > 0 || ( obj.size && obj.size > 0 ) );
};

export const isInstance = ( obj, iclass ) => {
    /**
    args:
        obj (any): the object to test
        iclass (Class): a class, or a list of classes, to compare to,
    return: bool: true if obj is an instance of iclass
    */
    try {
        return obj instanceof iclass;
    } catch( e ) {
        try {
            return iclass.filter( c => obj instanceof c ).length > 0;
        } catch( ee ) {
            throw new TypeError( `class or list of classes expected not ${ iclass }` );
        }
    }

};

export const isClass = ( obj ) => {
    /**
    args:
        obj (any): the object to test
    return: bool: true if obj is a class object
    NB see https://zaiste.net/posts/javascript-class-function/
    NB this works for ES6 classes
    */
    return typeof obj === 'function' &&
                            /^class\s/.test( Function.prototype.toString.call( obj ) );
};

// NB isClassProto() is in lib/ClassUtil


export const isSubclass = function( sub, base ) {
    /* perhaps should be called isClassOrSubclass() */
    return sub.prototype instanceof base || sub === base;
};

export const getClassName = function( o ) {
    if( typeof o === "function" && o.name ) {
        return o.name;      // o is a class or a function
    }
    return o.constructor.name;
};

// -------------------------------------------------------------------------------------------------
export const reprHelper = function( instance, order_or_props ) {
    /* TEST-√ */
    var props, order, strs = [];

    if( !isDef( order_or_props ) ) {
        order = Object.keys( instance );
        order.sort();
        props = {};
    }
    else if( isString( order_or_props ) ) {
        order = order_or_props.split( " " );
        props = {};
    } else {
        props = order_or_props;
        order = props._order;
        if( isDef( order ) ) {
            delete( props._order );
            order = order.split( " " );
        }
        var pnames = Object.keys( props );
        if( !order ) {
            order = pnames.sort();

        } else {       // keys missed from order appear at end
            var no_ords = pnames.filter( p => !order.includes( p ) );
            order = [ ...order, ...no_ords ];
        }
    }

    order.forEach( key => {
        let val = props[ key ];
        if( !isDef( val ) ) {
            val = instance[ key ];
        }
        strs.push( `${ key }: ${ JSON.stringify( val ) }` );
    } );

    // see https://stackoverflow.com/questions/10314338/get-name-of-object-or-class
    //    this will do for now (module would be nice also one day)
    var klass_name = instance.constructor.name;
    return `${ klass_name }({${ strs.join( ", " ) }})`;
};

export const arrayFromArgs = function( args, ofst=0 ) {
    /**
    convert args into a real array
    args:
        args (arguments): function arguments
        ofst (int): the number of initial arguments to skip
    return:
        array: the arguments as an array
    */
    return Array.prototype.slice.call( args, ofst );
};

// -------------------------------------------------------------------------------------------------
export const string = {
    /**
    utilities to assist with strings
    */
    contains: function( str, value ) {
        /* TEST-√ */
        return str.indexOf( value ) >= 0;
    },
    // deprecate includes: function( str, value ) {
    // deprecate     /* TEST-√ */
    // deprecate     return str.indexOf( value ) >= 0;
    // deprecate },

    splitWords: function( str ) {
        /**
        split a sting into separate words
        args:
            str (string): the string to access
        return:
            array: the split components
        */
        // FIXME - deal with "," separated too ???
        str = str.trim();
        return str ? str.split( /\s+/ ) : [];
    },

    lTrim: function( s ) {
        return s.replace( /^\s*/g, "" );
    },

    rTrim: function( s ) {
        return s.replace( /\s*$/g, "" );
    },
}
// -------------------------------------------------------------------------------------------------
export const list = {

    extend: function( dest, source ) {
        /* TEST-√ */
        dest.push.apply( dest, source );
    },

    pretend: function( dest, source ) {
        /**
        add the contents the source array to the start of an array
        args:
            dest (array): the array to be extended
            source (array): the array whose contents are added to arr
        */
        dest.unshift.apply( dest, source );
    },


    reversed: function( list ) {
        /* TEST-√ */
        list = list.slice();
        list.reverse();
        return list;
    },
    sorted: function( alist, keyFunc, cmpFunc ) {
        function _cmpFunc( a, b ) {
            var ka = keyFunc( a );
            var kb = keyFunc( b );
            return ka === kb ? 0 : ka < kb ? -1 : 1;
        }
        cmpFunc = keyFunc ? _cmpFunc : cmpFunc;
        alist = alist.slice();
        alist.sort( cmpFunc );
        return alist;
    },

    // deprecated comp: function( list, expr, cond ) {
    // deprecated     /* DEPRECATED - use array.map() */
    // deprecated     /*
    // deprecated     expr is func that takes item from list
    // deprecated         ( list_item ) => { return ... }
    // deprecated     cond is func that takes item from list + output of expression and returns bool
    // deprecated         to determine if item should be added to return list -
    // deprecated         ( list_item, expr_result ) => { return bool ... }
    // deprecated     */
    // deprecated     /* TEST-√ */
    // deprecated     if( !exports.isList( list ) ) {
    // deprecated         // 200508 - guard against iterators
    // deprecated         throw new Exception.TypeError( `invalid type for list comp! ${ list }` );
    // deprecated     }
    // deprecated     var _list = [], _item;
    // deprecated     for( let i = 0; i < list.length; i++ ) {
    // deprecated         _item = expr( list[ i ] );
    // deprecated         if( !cond || cond( list[ i ], _item ) ) {
    // deprecated             _list.push( _item );
    // deprecated         }
    // deprecated     }
    // deprecated     return _list;
    // deprecated },

    zip: function( ...lists ) {
        /* TEST-√ */
        return lists[ 0 ].map( ( _, i ) => lists.map( l => isDef( l[ i ] ) ? l[ i ] : null ) );
    },

    remove: function( list, item ) {
        /* TEST-√ */
        let idx = list.indexOf( item );
        if( idx === -1 ) {
            throw new Exception.ValueError( "list.remove(x): x not in list" );
        }
        list.splice( idx, 1 );
    },

    sameValues: function( arra, arrb ) {
        /**
        do 2 arrays contain the same values in the same order?
        args:
            arra (array): the first array to compare
            arrb (array): the seocnd array to compare
        return:
            (bool): true if values in arra are the same as those in arrb
        */
        if( arra.length !== arrb.length ) {
            return false;
        }
        return JSON.stringify( arra ) === JSON.stringify( arrb );
    },


    first: function( arr, ofst=0 ) {
        /**
        args:
            arr (array): the array to access
            ofst (int): [opt - 0] offset from start
        return: (any): the first item in the array

        note:
            throws error if array is empty

        example:
            list.first( [ "zero", "one", "two", "three", "four" ] ) => "zero"

            list.first( [ "zero", "one", "two", "three", "four" ], 2 ) => "two"
        */
        if( !arr.length ) {
            throw new Error( "empty array - first()" );
        }
        return arr[ ofst ];
    },


    last: function( arr, ofst=-1 ) {
        /**
        args:
            arr (array): the array to access
            ofst (int): [opt - -1] offset from end (should be negative)
        return:
            (any): the last item in the array

        note:
            throws error if array is empty

        example:
            list.last( [ "zero", "one", "two", "three", "four" ] ) => "four"

            list.last( [ "zero", "one", "two", "three", "four" ], -2 ) => "three"
        */
        if( !arr.length ) {
            throw new Error( "empty array - last()" );
        }
        return arr[ arr.length + ofst ];
    },

    prev: function( arr, item ) {
        /**
        return the item which lies before to a given item in the array
        args:
            arr (array): the array to access
            item (any): the item to search for
        return:
            (any): the previous item

        note:
            returns null if item is first in the array

            throws exception if array is empty or item is not in array
        */
        if( !arr.length ) {
            throw new Error( "empty array - prev()" );
        }
        var idx = arr.indexOf( item );
        if( idx === -1 ) {
            throw new Error( "item not in array - prev()" );
        }
        if( idx > 0 ) {
            return arr[ idx - 1 ];
        }
        return null;
    },
    next: function( arr, item ) {
        /**
        return the item which lies after to a given item in the array
        args:
            arr (array): the array to access
            item (any): the item to search for
        return: (any): the next item

        note:
            returns null if item is last in the array

            throws exception if array is empty or item is not in array
        */
        if( !arr.length ) {
            throw new Error( "empty array - next()" );
        }
        var idx = arr.indexOf( item );
        if( idx === -1 ) {
            throw new Error( "item not in array - next()" );
        }
        if( idx < arr.length - 1 ) {
            return arr[ idx + 1 ];
        }
        return null;
    },

    // invoke: function( arr, meth_name ) {
    //     /**
    //     call a named method on all objects in the array
    //     args:
    //         arr (array): the array to be accessed - it should contain objects
    //     which all implement the method meth_name
    //         meth_name (string): the name of the method to be called
    //     return:
    //         array: the values returned by each call
    //     note:
    //         any additional arguments passed to this function are passed to
    //     the method called on the objects
    //     */
    //     var meth_args = Util.args.array( arguments, 2 );
    //     return arr.map( function( obj ) {
    //         var meth = obj[ meth_name ];
    //         return meth.apply( obj, meth_args );
    //     } );
    // },


    $iter_break: {},
    detect: function( arr, testFunc ) {
        /**
        return the first truthy result from testFunc
        args:
            arr (array): the array to access
            testFunc (function): the function to call for each item

        `function( iitem ) { ... return iitem; }`
        note:
            returns undefined if no item passes the test
        */
        var rslt;
        try {
            arr.forEach( function( item, idx ) {
                var test_rslt = testFunc( item, idx );
                if( test_rslt ) {
                    rslt = test_rslt;
                    throw list.$iter_break;
                }
            } );
        } catch( err ) {
            if( err !== list.$iter_break ) {
                throw err;
            }
        }
        return rslt;
    },


    without: function( arr, item ) {
        /**
        return the array minus item
        args:
            arr (array): the array to access
            item (any): the item to remove
        return:
            array: a new array containing all items except item
        note:
            returns a copy of arr if item is not in it
            uses === comparison
        */
        /* FIXME - this does the right thing and it has the right name but
            we should probly use indexOf and splice() */
        return arr.filter( function( it ) {
            return it !== item;
        } );
    },

};


// -------------------------------------------------------------------------------------------------
// python-inspired "builtins"
export const getAttr = function( obj, name, fallback ) {
    var attr = obj[ name ];
    if( isDef( attr ) ) {
        return attr;
    }
    if( isDef( fallback ) ) {
        return fallback;
    }
    throw new Error( "cannot get attr '" + name + "' from " + obj.toString() );
};
export const setAttr = function( obj, name, val ) {
    obj[ name ] = val;
};
export const hasAttr = function( obj, name, val ) {
    return isDef( obj[ name ] );
};
export const zapAttr = function( obj, name ) {
    if( exports.hasAttr( obj, name ) ) {
        delete obj[ name ];
    }
};

export const getItem = function( obj, name, fallback ) {
    return exports.getAttr( obj, name, fallback );
};
export const setItem = function( obj, name, val ) {
    exports.setAttr( obj, name, val );
};

