
export class HXRowsStore {

  constructor(allRows) {
    this.allRows = allRows;
    this.filteredRows = allRows;
  }

  getAllRowsCount() {
    return this.allRows.length;
  }
  getAllRows() {
    return this.allRows;
  }

  getFilteredRowsCount() {
    return this.filteredRows.length;
  }
  getFilteredRows() {
    return this.filteredRows;
  }

  getSlicedRows(startIdx, finishIdx) {
    return this.filteredRows.slice(startIdx, finishIdx);
  }


  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  updateFilteredRows(filters) {
    this.filteredRows = this.filterRows(this.allRows, filters);
  }

  filterRows(rows, filters) {
    /*
      filters is a dict of dicts, keys being column heads, values being dicts
          with 'isActive', 'condition' and 'value'.
    */
    const col_heads = this.getColumnHeads();
    const cond_names = this.getConditionNames();

    let filtRows = rows;
    for(let [colName, filtVals] of Object.entries(filters)) {
      if(!filtVals["isActive"]) {
        continue;
      }
      if(!filtVals["value"]) {
        continue;
      }
      if(col_heads.indexOf(colName) < 0) {
        console.warn(`invalid column name - '${colName}`);
        continue;
      }
      if(cond_names.indexOf(filtVals["condition"]) < 0) {
        console.warn(`invalid filter condition - '${filtVals["condition"]}`);
        continue;
      }
      filtRows = this.filterRowsOne(
        filtRows,
        colName,
        filtVals["condition"],
        filtVals["value"]
      );
    }
    return filtRows;
  }

  filterFunctions = {
    "eq": (x, y) => x === y,
    "ne": (x, y) => x !== y,
    "lt": (x, y) => x < y,
    "le": (x, y) => x <= y,
    "gt": (x, y) => x > y,
    "ge": (x, y) => x >= y,

    "equals": (x, y) => x === y,
    "contains": (x, y) => x.indexOf(y) >= 0,
    "omits": (x, y) => x.indexOf(y) < 0,
    "starts": (x, y) => x.startsWith(y),
    "ends": (x, y) => x.endsWith(y),
  };

  filterRowsOne(inRows, colName, condition, value) {
      /* filter rows based on a single filter */
      if( [ 'eq', 'ne', 'lt', 'le', 'gt', 'ge' ].includes(condition) ) {
        if(isNaN(Number(value))) {
          console.warn(`'${ value }' is not a number`);
          return inRows;
        }
        value = Number(value);
      }
      let cmp_func = this.filterFunctions[condition];
      let filtRows = inRows.filter(r => cmp_func(r[colName], value));
      return filtRows;
  }

  getColumnHeads() {
    if(!this.allRows && this.allRows.length) {
      throw new Error(`HXRowstore - no data`);
    }
    return Object.keys(this.allRows[0]);
  }

  getConditionNames() {
    return Object.keys(this.filterFunctions);
  }
}
