import { configureStore } from '@reduxjs/toolkit'

const initialState = {
  showHomePage: true,
  // dataFileName: '',
  dataFileName: 'beginner',
  selectedLanguage: 'EN',  // or 'EL'
  filters: {
    'word': {
      isActive: false,
      condition: 'contains',
      value: '',
    },
    'freq_pm': {
      isActive: false,
      condition: 'gt',
      value: '',
    },
    'letter_length': {
      isActive: false,
      condition: 'gt',
      value: '',
    },

    'OLD20orth': {
      isActive: false,
      condition: 'gt',
      value: '',
    },
    'OLD20phon': {
      isActive: false,
      condition: 'gt',
      value: '',
    },
  },
  wordsList: {
    isActive: false,
    dialogIsOpen: false,
    words: [],
    dialogWords: [],
  },
  help: {
    dialogIsOpen: false,
  },
}

function reducer(state = initialState, action) {
  const payload = action.payload;
  if (action.type === 'showHomePage') {
    return {
      ...state,
      showHomePage: payload
    }
  }
  if (action.type === 'dataFileName') {
    return {
      ...state,
      dataFileName: payload,
    }
  }
  if (action.type === 'selectedLanguage') {
    return {
      ...state,
      selectedLanguage: payload,
    }
  }

  if (action.type.startsWith('filter/')) {
    const oldFilter = state.filters[payload.filterName];
    const newFilter = {...oldFilter};
    switch (action.type) {
      case 'filter/isActive':
        newFilter.isActive = payload.isActive;
      break;
      case 'filter/condition':
        newFilter.condition = payload.condition;
      break;
      case 'filter/value':
        newFilter.value = payload.value;
      break;
      default:
        console.warn(`unknown reducer action type - ${ action.type }`);
        return state;
    }
    return {
      ...state,
      filters: {
        ...state.filters,
        [payload.filterName]: newFilter
      }
    }
  }

  if (action.type.startsWith('wordsList/')) {
    const oldWordsList = state.wordsList;
    const newWordsList = {...oldWordsList};
    switch (action.type) {
      case 'wordsList/isActive':
        newWordsList.isActive = payload;
      break;
      case 'wordsList/dialogIsOpen':
        newWordsList.dialogIsOpen = payload;
      break;
      case 'wordsList/words':
        newWordsList.words = payload;
      break;
      case 'wordsList/dialogWords':
        newWordsList.dialogWords = payload;
      break;
      default:
        console.warn(`unknown reducer action type - ${ action.type }`);
        return state;
    }
    return {
      ...state,
      wordsList: newWordsList,
    }
  }

  if (action.type.startsWith('help/')) {
    const oldHelp = state.help;
    const newHelp = {...oldHelp};
    switch (action.type) {
      case 'help/dialogIsOpen':
        newHelp.dialogIsOpen = payload;
      break;
      default:
        console.warn(`unknown reducer action type - ${ action.type }`);
        return state;
    }
    return {
      ...state,
      help: newHelp,
    }
  }

  return state;
}

export const store = configureStore({
  reducer: reducer,
  devTools: true,
})

