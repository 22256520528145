import React from "react";
import { useSelector, useDispatch } from 'react-redux'

import { HXWindow } from './HXWindow';
import { HXButton } from '../HXButton';

import { debounce } from '../../util/debounce';

export const HXWordListDialog = (props) => {

  let dialogWords = useSelector(state => state.wordsList.dialogWords);
  dialogWords = dialogWords.join('\n');

  const dispatch = useDispatch();
  const onChangeWords = (event) => {
    const newWords = event.target.value.split('\n');
    dispatch({type: 'wordsList/dialogWords', payload: newWords});
  }
  const onChangeWords_db = debounce(onChangeWords, 500);
  const handleCancel = () => {
    dispatch({type: 'wordsList/dialogIsOpen', payload: false});
  }
  const handleProceed = () => {
    dispatch({type: 'wordsList/words', payload: dialogWords.split('\n')});
    dispatch({type: 'wordsList/dialogIsOpen', payload: false});
    dispatch({type: 'wordsList/isActive', payload: true});
  }

  return (
    <HXWindow
      title="Word List"
    >
      <div name="body" className="-mp-lay-fl" style={{width:'100%'}}>
        <div name="paragraph_003"
          style={{boxSizing:'border-box',lineHeight:1.3,padding:'10px',width:'100%'}}>
            Enter the words you would like information about, one word per line.
        </div>

        <textarea name="word_list" className="box"
          style={{borderColor:'#888888',borderWidth:'1px 0px 1px 0px',boxSizing:'border-box',fontFamily:'sans-serif',fontSize:'16px',height:'256px',lineHeight:'1.4',maxWidth:'100%',minHeight:'256px',overflowX:'hidden',overflowY:'scroll',padding:'5px 20px 5px 20px',width:'100%'}}
          placeholder="enter words"
          autoComplete="off"
          defaultValue={dialogWords}
          onChange={onChangeWords_db}
        />

        <div name="buttons" className="bilex-dialog-btns -mp-lay-fl">
          <HXButton
            title="cancel"
            typeClass="-hx-button-warning"
            width={77}
            onClick={handleCancel}
          />

          <HXButton
            title="save"
            typeClass="-hx-button-success"
            width={77}
            onClick={handleProceed}
            extraStyle={{margin:'0px 0px 0px 6px'}}
          />
        </div>
      </div>
    </HXWindow>
  )
}
