import React from "react";
import { useDispatch } from 'react-redux'

import { HXWindow } from './HXWindow';
import { HXButton } from '../HXButton';

export const HXHelpDialog = (props) => {

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch({type: 'help/dialogIsOpen', payload: false});
  }

  return (
    <HXWindow
      title="Help"
      width={460}
    >
      <div name="body" className="-mp-lay-fl" style={{height:'360px',lineHeight:'1.6',overflowX:'hidden',overflowY:'scroll',width:'100%',}}>
        <div name="contain_paragraph" id="~help_dlog~contents~body~contain_paragraph" style={{boxSizing:'border-box',padding:'0px 20px 0px 20px',width:'100%',}}>
          <p name="p" className="p1"><b name="!nkkh">FILTER&nbsp; “WORD”</b>
          </p>
          <p name="p_099" className="p1">By checking this filter you can search for words with five different functions:</p>
          <p name="p_100" className="p1">&nbsp;“contains” = type specific letters or letter combinations that you want the words to contain.</p>
          <p name="p_101" className="p1">&nbsp;“equals”&nbsp; &nbsp; = type whole words you are interested in.&nbsp;</p>
          <p name="p_102" className="p1">“omits”&nbsp; &nbsp; &nbsp; = type specific letters or letter combinations which you do not want the words to contain.</p>
          <p name="p_103" className="p1">&nbsp;“starts”&nbsp; &nbsp; = search for words that start with specific letters or letter combinations.</p>
          <p name="p_104" className="p1">&nbsp;“ends” &nbsp; &nbsp; = search for words that end in specific letters or letter combinations.</p>
          <p name="p_107" className="p1 head" style={{fontWeight:'bold'}}>FILTER “FREQUENCY COUNTS”</p>
          <p name="p_108" className="p1">By checking this filter you can search for words with six different options:</p>
          <p name="p_109" className="p1">“=”&nbsp; (equals): type a number and it will search for words that have a specific frequency value.&nbsp;</p>
          <p name="p_110" className="p1">“!=” (not equal to): type a number and it will search for words that do not have a specific frequency value.&nbsp;</p>
          <p name="p_111" className="p1">“&lt;”&nbsp; (less than): type a number and it will search for words with a frequency value less than the one specified.</p>
          <p name="p_112" className="p1">“&lt;=” (less than or equal to):&nbsp; type a number and it will search for words with a frequency value less than or equal to the one specified.</p>
          <p name="p_113" className="p1">“&gt;” (greater than): type a number and it will search for words with a frequency value greater than the one specified.</p>
          <p name="p_114" className="p1">“&gt;=” (greater than or equal to) : type a number and it will search for words with a frequency value greater than equal to the one specified.</p>
          <p name="p_117" className="p1 head" style={{fontWeight:'bold'}}>FILTER “FREQUENCY PER MILLION”:</p>
          <p name="p_118" className="p1">By checking this filter you can search for words with six different options:</p>
          <p name="p_119" className="p1">“=”&nbsp; (equals): type a number and it will search for words that have a specific frequency per million value.&nbsp;</p>
          <p name="p_120" className="p1">“!=” (not equal to): type a number and it will search for words that do not have a specific frequency per million value.&nbsp;</p>
          <p name="p_121" className="p1">“&lt;”&nbsp; (less than): type a number and it will search for words with a frequency er million value less than the one specified.</p>
          <p name="p_122" className="p1">“&lt;=” (less than or equal to):&nbsp; type a number and it will search for words with a frequency per million value less than or equal to the one specified.</p>
          <p name="p_123" className="p1">“&gt;” (greater than): type a number and it will search for words with a frequency per million value greater than the one specified.</p>
          <p name="p_124" className="p1">“&gt;=” (greater than or equal to) : type a number and it will search for words with a frequency per million value greater than equal to the one specified.</p>
          <p name="p_127" className="p1 head" style={{fontWeight:'bold'}}>FILTER “LOG FREQUENCY PER MILLION”:</p>
          <p name="p_128" className="p1">By checking this filter you can search for words with six different options:</p>
          <p name="p_129" className="p1">“=”&nbsp; (equals): type a number and it will search for words that have a specific Log frequency per million value.&nbsp;</p>
          <p name="p_130" className="p1">“!=” (not equal to): type a number and it will search for words that do not have a specific Log frequency per million value.&nbsp;</p>
          <p name="p_131" className="p1">“&lt;”&nbsp; (less than): type a number and it will search for words with a Log frequency per million value less than the one specified.</p>
          <p name="p_132" className="p1">“&lt;=” (less than or equal to):&nbsp; type a number and it will search for words with a Log frequency per million value less than or equal to the one specified.</p>
          <p name="p_133" className="p1">“&gt;” (greater than): type a number and it will search for words with a Log frequency per million value greater than the one specified.</p>
          <p name="p_134" className="p1">“&gt;=” (greater than or equal to): type a number and it will search for words with a Log frequency per million value greater than equal to the one specified.</p>
          <p name="p_138" className="p1 head" style={{fontWeight:'bold'}}>FILTER “DISPERSION”:</p>
          <p name="p_139" className="p1">By checking this filter you can search for words with six different options:</p>
          <p name="p_140" className="p1">“=”&nbsp; (equals): type a number and it will search for words that have a specific Dispersion value.&nbsp;</p>
          <p name="p_141" className="p1">“!=” (not equal to): type a number and it will search for words that do not have a specific Dispersion value.&nbsp;</p>
          <p name="p_142" className="p1">“&lt;”&nbsp; (less than): type a number and it will search for words with a Dispersion less than the one specified.</p>
          <p name="p_143" className="p1">“&lt;=” (less than or equal to):&nbsp; type a number and it will search for words with a Dispersion less than or equal to the one specified.</p>
          <p name="p_144" className="p1">“&gt;” (greater than): type a number and it will search for words with a Dispersion value greater than the one specified.</p>
          <p name="p_145" className="p1">“&gt;=” (greater than or equal to): type a number and it will search for words with a Dispersion value greater than equal to the one specified.</p>
          <p name="p_148" className="p1 head" style={{fontWeight:'bold'}}>FILTER “ESTIMATED FREQUENCY PER MILLION (U)”:</p>
          <p name="p_149" className="p1">By checking this filter you can search for words with six different options:</p>
          <p name="p_150" className="p1">“=”&nbsp; (equals): type a number and it will search for words that have a specific Estimated Frequency per million value.&nbsp;</p>
          <p name="p_151" className="p1">“!=” (not equal to): type a number and it will search for words that do not have a specific Estimated Frequency per million value.&nbsp;</p>
          <p name="p_152" className="p1">“&lt;”&nbsp; (less than): type a number and it will search for words with an Estimated Frequency per million less than the one specified.</p>
          <p name="p_153" className="p1">“&lt;=” (less than or equal to):&nbsp; type a number and it will search for words with an Estimated Frequency per million less than or equal to the one specified.</p>
          <p name="p_154" className="p1">“&gt;” (greater than): type a number and it will search for words with an Estimated Frequency per million value greater than the one specified.</p>
          <p name="p_155" className="p1">“&gt;=” (greater than or equal to): type a number and it will search for words with an Estimated Frequency per million value greater than equal to the one specified.</p>
          <p name="p_159" className="p1 head" style={{fontWeight:'bold'}}>FILTER “STANDARD FREQUENCY INDEX (SFI)”:</p>
          <p name="p_160" className="p1">By checking this filter you can search for words with six different options:</p>
          <p name="p_161" className="p1">“=”&nbsp; (equals): type a number and it will search for words that have a specific Standard Frequency Index value.&nbsp;</p>
          <p name="p_162" className="p1">“!=” (not equal to): type a number and it will search for words that do not have a specific Standard Frequency Index value.&nbsp;</p>
          <p name="p_163" className="p1">“&lt;”&nbsp; (less than): type a number and it will search for words with a Standard Frequency Index value less than the one specified.</p>
          <p name="p_164" className="p1">“&lt;=” (less than or equal to):&nbsp; type a number and it will search for words with a Standard Frequency Index value less than or equal to the one specified.</p>
          <p name="p_165" className="p1">“&gt;” (greater than): type a number and it will search for words with a Standard Frequency Index value greater than the one specified.</p>
          <p name="p_166" className="p1">“&gt;=” (greater than or equal to): type a number and it will search for words with a Standard Frequency Index value greater than equal to the one specified.</p>
          <p name="p_170" className="p1 head" style={{fontWeight:'bold'}}>FILTER “CONTEXTUAL DIVERSITY (CD)”:</p>
          <p name="p_171" className="p1">By checking this filter you can search for words with six different options:</p>
          <p name="p_172" className="p1">“=”&nbsp; (equals): type a number and it will search for words that have a specific Contextual Diversity value.&nbsp;</p>
          <p name="p_173" className="p1">“!=” (not equal to): type a number and it will search for words that do not have a specific Contextual Diversity value.&nbsp;</p>
          <p name="p_174" className="p1">“&lt;”&nbsp; (less than): type a number and it will search for words with a Contextual Diversity value less than the one specified.</p>
          <p name="p_175" className="p1">“&lt;=” (less than or equal to):&nbsp; type a number and it will search for words with a Contextual Diversity value less than or equal to the one specified.</p>
          <p name="p_176" className="p1">“&gt;” (greater than): type a number and it will search for words with a Contextual Diversity value greater than the one specified.</p>
          <p name="p_177" className="p1">“&gt;=” (greater than or equal to): type a number and it will search for words with a Contextual Diversity value greater than or equal to the one specified.</p>
          <p name="p_181" className="p1 head" style={{fontWeight:'bold'}}>FILTER “NUMBER OF LETTERS (NLetters)”:</p>
          <p name="p_182" className="p1">By checking this filter you can search for words with six different options:</p>
          <p name="p_183" className="p1">“=”&nbsp; (equals): type a number and it will search for words that have a specific number of letters.&nbsp;</p>
          <p name="p_184" className="p1">“!=” (not equal to): type a number and it will search for words that do not have the specified number of letters.&nbsp;</p>
          <p name="p_185" className="p1">“&lt;”&nbsp; (less than): type a number and it will search for words with number of&nbsp; letters less than the one specified.</p>
          <p name="p_186" className="p1">“&lt;=” (less than or equal to):&nbsp; type a number and it will search for words with number of letters less than or equal to the one specified.</p>
          <p name="p_187" className="p1">“&gt;” (greater than): type a number and it will search for words with number of letters greater than the one specified.</p>
          <p name="p_188" className="p1">“&gt;=” (greater than or equal to): type a number and it will search for words with number of letters greater than or equal to the one specified.</p>
          <p name="p_189" className="p1"></p>
          <p name="p_190" className="p1 head" style={{fontWeight:'bold'}}>FILTER “SELECT”:</p>
          <p name="p_191" className="p1">Check “words list” and then press “change list”. Enter the word(s) – one per line- and press ”save”. The list of words will be returned with the values. If filters are checked, the returned list will take account of them. If a word is not in the lexicon, it will still appear in the list but with no values. All words will be returned in the same order they were entered.</p>
          <p name="p_194" className="p1 head" style={{fontWeight:'bold'}}>DOWNLOAD:</p>
          <p name="p_192" className="p1">You can download the data for the words currently displayed in the table using the “download csv” button. You can then open the file in a spreadsheet program or import the data into a database.</p>
          <p name="p_193" className="p2"><br/>
          </p>
          <div name="paragraph_002" style={{boxSizing:'border-box',lineHeight:'1.3',padding:'10px',}}>&nbsp;</div>
        </div>
      </div>
      <div name="buttons" className="-mp-dialog-btns -mp-lay-fl">
        
        <HXButton 
          title="close"
          typeClass="-hx-button-warning"
          width={77}
          onClick={handleClose}
          extraStyle={{margin:'0px 0px 0px 6px'}}
        />
      </div> 

    </HXWindow>
    
  )
}
