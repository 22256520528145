import {useNavigate} from 'react-router-dom';

import bilexLogo from '../images/BILEX-LOGO-3-1.png';
import headImage from '../images/bilex-kids-head-text.svg'

export function BXKidsHeader() {
  const navigate = useNavigate();

  return (
    <div
      style={{margin:'-5px 0px 0px 0px',}}
    >
      <header
        style={{alignItems:'center',display:'flex',flexDirection:'row',height:'84px',justifyContent:'space-around',minWidth:'160px',width:'100%',}}
      >
        <img
          className="bilex-logo"
          style={{height:'84.5156px',width:'245px',}}
          src={headImage}
          alt=""
          />
        <div
          style={{display:'flex'}}
        >
          <a
            name="home_button"
            style={{color:'#ffffff',fontSize:'22px',margin:'0px 20px 0px 20px',}}
            href="/home">
            Home
          </a>
          <a
            name="plus_button"
            style={{color:'#ffffff',fontSize:'22px',margin:'0px 20px 0px 20px',}}
            href="/#/bilex-plus/menu">
            Plus
          </a>
        </div>
        <img name="lang_sel_image"
          className="bilex-logo"
          style={{width:'109px'}}
          src={bilexLogo}
          alt=""
        />
      </header>
      <div
        name="back_button_container"
        className="back-button-container -mp-lay-fl">
        <button name="back_button"
          style={{color:'#ffffff',fontSize:'22px',margin:'0px 20px 0px 20px',}}
          onClick={() => navigate(-1)}
        >
          Back
        </button>
      </div>
    </div>
  )
}