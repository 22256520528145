import React, { useState, useRef } from "react";
import { useSelector } from 'react-redux'

import { loadRowStore } from '../data/LoadRowStore';
import { objectsToCsv } from '../data/HXObjectsToCsv';
import { calculateCsvDataUrl } from '../data/calculateDataUrl';

import { HXButton } from './HXButton';


export const BXDownloadCsv = (props) => {
  const downloadElem = useRef(null);

  const [downloadUrl, setDownloadUrl] = useState(null);

  const dataFileName = useSelector(state => state.dataFileName)
  const dataUrl = calculateCsvDataUrl(dataFileName);

  const filters = useSelector(state => state.filters)
  const wordsList = useSelector(state => state.wordsList)

  const doDownload = async () => {
    const rowsStore = await loadRowStore(dataUrl, wordsList)

    rowsStore.updateFilteredRows(filters);

    const rowsData = rowsStore.getFilteredRows();
    const csvData = objectsToCsv(rowsData, rowsStore.getColumnHeads());
    const blob = new Blob([csvData]);

    const newDownloadUrl = URL.createObjectURL(blob);
    setDownloadUrl(newDownloadUrl);

    // something of a hack until we work out something better
    setTimeout( () => {
      downloadElem.current.click();
      URL.revokeObjectURL(newDownloadUrl);  // free up storage--no longer needed.
      setDownloadUrl('')
    }, 500);
  }

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

  return (
    <>
      <HXButton
        title="download extract as csv"
        typeClass="-hx-button-success"
        width={200}
        extraStyle={{float:'right',margin:'6px 15px 0px 0px',width:'200px',}}
        onClick={doDownload}
      />

      <a
        ref={downloadElem}
        href={downloadUrl}
        download={'BilexWordFrequencies.csv'}
        style={{display:'none'}}
      >
        download
      </a>
    </>
  )
}

BXDownloadCsv.propTypes = {
}
