import React from "react";
import { useSelector } from 'react-redux'

import * as constants from '../constants';

const dataFileNameToLevel = (dataFileName) => {
  const found = Object.entries(constants.LEVEL_TO_DATAFILE_NAME).filter( ([k, v]) => {
    return v === dataFileName;
  });
  return found.length ? found[0][0] : '?';
}

export const BXTranslationLevel = (props) => {
  const dataFileName = useSelector(state => state.dataFileName);
  const level = dataFileNameToLevel(dataFileName);
  return (

    <div name="level_display" style={{width:'100%',}}>
      {
        level === 'beginner' &&
        <div name="beginner">
          <div className="level-en lang-en">Beginner</div>
          <div className="level-el lang-el">ΑΡΧΑΡΙΟΣ</div>
        </div>
      }
      {
        level === 'intermediate' &&
        <div name="intermediate">
          <div className="level-en lang-en">Intermediate</div>
          <div className="level-el lang-el">ΕΝΔΙΑΜΕΣΟΣ</div>
        </div>
      }
      {
        level === 'advanced' &&
        <div name="advanced">
          <div className="level-en lang-en">Advanced</div>
          <div className="level-el lang-el">ΠΡΟΧΩΡΗΜΕΝΟΣ</div>
        </div>
      }
      {
        level === 'expert' &&
        <div name="expert">
          <div className="level-en lang-en">Expert</div>
          <div className="level-el lang-el">ΕΜΠΕΙΡΟΓΝΩΜΟΝΑΣ<br />
          </div>
        </div>
      }
    </div>
  )
}