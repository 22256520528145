import React from "react";
import PropTypes from 'prop-types';

import { HXTableRow } from './HXTableRow';

const ROW_HEIGHT = 28;
const INSET_ROWS = 0;

export const HXTableBody = (props) => {

  const numRowsShown = Math.ceil(props.sheetHeight / ROW_HEIGHT) - 2 * INSET_ROWS;
  const numDataRows = props.rowStore.getFilteredRows().length;

  let numRowsAbove;
  let numRowsBelow;

  if(numDataRows < numRowsShown) {
    numRowsAbove = INSET_ROWS;
    numRowsBelow = 0;
  } else {
    numRowsAbove = Math.floor(props.scrollDistance / ROW_HEIGHT) + INSET_ROWS;
    numRowsBelow = numDataRows - numRowsAbove - numRowsShown + INSET_ROWS;
  }

  let rowAboveHeight = numRowsAbove * ROW_HEIGHT;
  const rowBelowHeight = numRowsBelow * ROW_HEIGHT;
  const dataRowsHeight = numDataRows * ROW_HEIGHT;

  // console.log({
  //   numDataRows,
  //   numRowsShown,
  //   numRowsAbove,
  //   numRowsBelow,
  // });

  // const totalHeight = rowAboveHeight + rowBelowHeight + numRowsShown * ROW_HEIGHT;
  // console.log({
  //   sheetHeight: props.sheetHeight,
  //   rowAboveHeight,
  //   rowBelowHeight,
  //   totalHeight,    
  //   dataRowsHeight,
  // });

  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  const renderRowAbove = () => {
    if(rowAboveHeight <= 0) {
      return null;
    }

    return (
      <tr>
        <td
          colSpan={props.columnNames.length + 1}
          style={{height:`${rowAboveHeight}px`,backgroundColor:'transparent'}}
        >
          &nbsp;
        </td>
      </tr>
    )
  }

  const renderRowBelow = () => {
    if(rowBelowHeight <= 0) {
      return null;
    }

    return (
      <tr>
        <td
          colSpan={props.columnNames.length + 1}
          style={{height:`${rowBelowHeight}px`,backgroundColor:'transparent'}}
        >
          &nbsp;
        </td>
      </tr>
    )
  }

  return (
    <>
      <tr
        className="ghostRowPreventsAutoScroll"
        style={{
          height:`${dataRowsHeight}px`,
        }}
      />
      {renderRowAbove()}

      {
        props.rowStore.getSlicedRows(numRowsAbove, numRowsAbove + numRowsShown).map( (rowData, rowIdx) => { 
          return <HXTableRow 
            isVisible={true}
            columnInfos={props.columnInfos}
            columnNames={props.columnNames}
            rowIndex={rowIdx}
            rowData={rowData}
            key={rowIdx}        
          />
        })
      }
      {renderRowBelow()}
    </>
  )
}


HXTableBody.propTypes = {
  columnInfos: PropTypes.object,
  columnNames: PropTypes.arrayOf(PropTypes.string),
  rowStore: PropTypes.object,
  scrollDistance: PropTypes.number,
  sheetHeight: PropTypes.number,
}
