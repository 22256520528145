import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";

import { BXKidsFooter } from '../ui/BXKidsFooter';
import { BXKidsHeader } from '../ui/BXKidsHeader';

export function BXSelectLanguagePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goToMenuWithLanguage = (selectedLanguage) => {
    dispatch({type: 'selectedLanguage', payload: selectedLanguage});
    /* LINK */
    navigate('/bilex-kids/menu');
  }

  return (
    <div
      name="page_contents"
      className="page-contents"
    >
      <div
        name="slot"
        className="vert-space-between">

        <BXKidsHeader />

        <div name="slot"
          style={{flexGrow:1,minHeight:'160px',minWidth:'100px',width:'100%',margin:'0px',}}
        >
          <div
            name="container"
            style={{display:'flex',flexDirection:'row',height:'75px',justifyContent:'space-around',width:'100',}}
          >
            <button
              name="english_button"
              className="-mp-lay-fl lang-select-button"
              onClick={()=>goToMenuWithLanguage('EN')}
            >
              <div
                style={{color:'#ffd00d',fontSize:'24px',fontWeight:'bolder',textAlign:'center',}}
              >
                English
              </div>
            </button>
            <button
              name="greek_button"
              className="-mp-lay-fl lang-select-button"
              onClick={()=>goToMenuWithLanguage('EL')}
            >
              <div
                style={{color:'#ffd00d',fontSize:'24px',fontWeight:'bolder',textAlign:'center',}}
              >
                Greek
              </div>
            </button>
          </div>
        </div>
        <BXKidsFooter />
      </div>
    </div>
  );
}
